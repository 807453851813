import {Component, OnInit} from '@angular/core';
import {WalletName, WalletReadyState} from '@solana/wallet-adapter-base';
import {Keypair, PublicKey, SystemProgram, Transaction} from '@solana/web3.js';
import bs58, {encode} from 'bs58';
import {defer, from, throwError, Observable} from 'rxjs';
import {concatMap, first, map} from 'rxjs/operators';
import {ConnectionStore, WalletStore} from '@heavy-duty/wallet-adapter';

import {filter} from 'rxjs/operators';
import {
    createMint,
    getOrCreateAssociatedTokenAccount,
    createTransferInstruction,
    mintTo,
    transfer,
    TOKEN_PROGRAM_ID,
    ASSOCIATED_TOKEN_PROGRAM_ID,
    createAssociatedTokenAccountInstruction,
    getAssociatedTokenAddress
} from '@solana/spl-token';
import {environment} from 'src/environments/environment';

export const isNotNull = <T>(source: Observable<T | null>) =>
    source.pipe(filter((item: T | null): item is T => item !== null));

@Component({
    selector: 'app-phantom',
    templateUrl: './phantom.component.html',
    styleUrls: ['./phantom.component.css']
})
export class PhantomComponent implements OnInit {
    WACH: any = new PublicKey(environment.WACH_ADDRESS);
    readonly connection$ = this._connectionStore.connection$;
    readonly wallets$ = this._walletStore.wallets$;
    readonly wallet$ = this._walletStore.wallet$;
    readonly walletName$ = this.wallet$.pipe(map((wallet: any) => wallet?.adapter.name || null));
    readonly ready$ = this.wallet$.pipe(
        map(
            (wallet: any) =>
                wallet &&
                (wallet?.adapter.readyState === WalletReadyState.Installed ||
                    wallet?.adapter.readyState === WalletReadyState.Loadable)
        )
    );
    readonly connected$ = this._walletStore.connected$;
    readonly publicKey$ = this._walletStore.publicKey$;
    lamports = 0;
    recipient = '';

    constructor(
        private readonly _connectionStore: ConnectionStore,
        private readonly _walletStore: WalletStore
    ) {

    }

    async getTokenAccount(fromPubkey: PublicKey, isSolToken: boolean = true) {
        console.log('fromPubkey', fromPubkey.toString());
        const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
            'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL',
        );

        const formToken = (isSolToken ? fromPubkey : this.WACH)

        console.log('WACH', this.WACH.toString());
        console.log('formToken', formToken.toString());
        // findProgramAddress
        const tokenAccount = (await PublicKey.findProgramAddress(
            [
                fromPubkey.toBuffer(),
                TOKEN_PROGRAM_ID.toBuffer(),
                formToken.toBuffer()
                ,
            ],
            SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
        ))[0];

        console.log('tokenAccount', tokenAccount.toString());

        return tokenAccount;
    }

    async createTokenAccount(fromPubkey: PublicKey) {
        let allowOwnerOffCurve = false;
        let programId = TOKEN_PROGRAM_ID;
        let associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID;
        const mint = this.WACH;
        // let owner = new PublicKey(fromPubkey.toString());
        let owner = new PublicKey(this.recipient);
        const associatedToken = await getAssociatedTokenAddress(
            mint,
            owner,
            allowOwnerOffCurve,
            programId,
            associatedTokenProgramId
        );

        this.connection$
            .pipe(
                first(),
                isNotNull,
                concatMap((connection) =>
                    from(defer(() => connection.getLatestBlockhash())).pipe(
                        concatMap(({blockhash, lastValidBlockHeight}) =>
                            this._walletStore.sendTransaction(
                                new Transaction({
                                    blockhash,
                                    feePayer: fromPubkey,
                                    lastValidBlockHeight,
                                }).add(
                                    createAssociatedTokenAccountInstruction(
                                        fromPubkey,
                                        associatedToken,
                                        owner,
                                        mint,
                                        programId,
                                        associatedTokenProgramId
                                    )
                                ),
                                connection
                            )
                        )
                    )
                )
            )
            .subscribe({
                next: (signature) => {
                    console.log(`Transaction sent (${signature})`);
                    this.onSendWACH(fromPubkey)
                },
                error: (error) => console.error(error),
            });
    }

    ngOnInit(): void {
        // this.onConnect();
        // this.onSelectWallet('Phantom');
    }

    onConnect() {
        this._walletStore.connect().subscribe();
        console.log('onConnect', this._walletStore);

    }

    onDisconnect() {
        this._walletStore.disconnect().subscribe();
    }

    onSelectWallet(walletName: WalletName | any) {
        console.log('walletName', walletName)
        this._walletStore.selectWallet(walletName);
    }

    async onSendWACH(fromPubkey: PublicKey) {
        const formToken = await this.getTokenAccount(fromPubkey, false);
        const toToken = await this.getTokenAccount(new PublicKey(this.recipient), false);
        // const toToken = await this.getOrCreateTokenAccount(fromPubkey);

        console.log('formToken', formToken.toString());
        console.log('toToken', toToken);

        this.connection$
            .pipe(
                first(),
                isNotNull,
                concatMap((connection) =>
                    from(defer(() => connection.getLatestBlockhash())).pipe(
                        concatMap(({blockhash, lastValidBlockHeight}) =>
                            this._walletStore.sendTransaction(
                                new Transaction({
                                    blockhash,
                                    feePayer: fromPubkey,
                                    lastValidBlockHeight,
                                }).add(
                                    createTransferInstruction(
                                        formToken,
                                        toToken,
                                        fromPubkey,
                                        this.lamports,
                                        [],
                                        TOKEN_PROGRAM_ID
                                    )
                                ),
                                connection
                            )
                        )
                    )
                )
            )
            .subscribe({
                next: (signature) => console.log(`Transaction sent (${signature})`),
                error: (error: any) => {
                    this.createTokenAccount(fromPubkey);
                },
            });
    }

    onSendTransaction(fromPubkey: PublicKey) {
        this.connection$
            .pipe(
                first(),
                isNotNull,
                concatMap((connection) =>
                    from(defer(() => connection.getLatestBlockhash())).pipe(
                        concatMap(({blockhash, lastValidBlockHeight}) =>
                            this._walletStore.sendTransaction(
                                new Transaction({
                                    blockhash,
                                    feePayer: fromPubkey,
                                    lastValidBlockHeight,
                                }).add(
                                    SystemProgram.transfer({
                                        fromPubkey,
                                        toPubkey: new PublicKey(this.recipient),
                                        lamports: this.lamports
                                    })
                                ),
                                connection
                            )
                        )
                    )
                )
            )
            .subscribe({
                next: (signature) => console.log(`Transaction sent (${signature})`),
                error: (error) => console.error(error),
            });
    }

    onSignTransaction(fromPubkey: PublicKey) {
        this.connection$
            .pipe(
                first(),
                isNotNull,
                concatMap((connection) =>
                    from(defer(() => connection.getLatestBlockhash())).pipe(
                        map(({blockhash, lastValidBlockHeight}) =>
                            new Transaction({
                                blockhash,
                                feePayer: fromPubkey,
                                lastValidBlockHeight
                            }).add(
                                SystemProgram.transfer({
                                    fromPubkey,
                                    toPubkey: new PublicKey(this.recipient),
                                    lamports: this.lamports,
                                })
                            )
                        )
                    )
                ),
                concatMap((transaction) => {
                    const signTransaction$ =
                        this._walletStore.signTransaction(transaction);

                    if (!signTransaction$) {
                        return throwError(
                            () => new Error('Sign transaction method is not defined')
                        );
                    }

                    return signTransaction$;
                })
            )
            .subscribe({
                next: (transaction) => console.log('Transaction signed', transaction),
                error: (error) => console.error(error)
            });
    }

    onSignAllTransactions(fromPubkey: PublicKey) {
        this.connection$
            .pipe(
                first(),
                isNotNull,
                concatMap((connection) =>
                    from(defer(() => connection.getLatestBlockhash())).pipe(
                        map(({blockhash, lastValidBlockHeight}) =>
                            new Array(3).fill(0).map(() =>
                                new Transaction({
                                    blockhash,
                                    feePayer: fromPubkey,
                                    lastValidBlockHeight
                                }).add(
                                    SystemProgram.transfer({
                                        fromPubkey,
                                        toPubkey: new PublicKey(this.recipient),
                                        lamports: this.lamports,
                                    })
                                )
                            )
                        )
                    )
                ),
                concatMap((transactions) => {
                    const signAllTransaction$ =
                        this._walletStore.signAllTransactions(transactions);

                    if (!signAllTransaction$) {
                        return throwError(
                            () => new Error('Sign all transactions method is not defined')
                        );
                    }

                    return signAllTransaction$;
                })
            )
            .subscribe({
                next: (transactions) => console.log('Transactions signed', transactions),
                error: (error) => console.error(error)
            });
    }

    onSignMessage() {
        const signMessage$ = this._walletStore.signMessage(
            new TextEncoder().encode('Hello world!')
        );

        if (!signMessage$) {
            return console.error(new Error('Sign message method is not defined'));
        }

        signMessage$.pipe(first()).subscribe((signature) => {
            console.log(`Message signature: ${{encode}.encode(signature)}`);
        });
    }


}
