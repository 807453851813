import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})

/**
 * Grid component - handling the grid with navbar and content
 */
export class GridComponent implements OnInit {
  // bread crumb items
  breadCrumbItems: Array<any>;

  constructor() { }

  ngOnInit() {
    // tslint:disable-next-line: max-line-length
    this.breadCrumbItems = [{ label: 'Aivis', path: '/' }, { label: 'UI Elements', path: '/' }, { label: 'Grid', path: '/', active: true }];

  }

}
