import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';

@Component({
    selector: 'app-webcam',
    templateUrl: './webcam.component.html',
    styleUrls: ['./webcam.component.scss']
})
export class WebcamComponent implements AfterViewInit {
    WIDTH = 460;
    HEIGHT = 220;

    @ViewChild('video', {static: false}) video: ElementRef;

    @ViewChild('canvas', {static: true}) canvas: ElementRef;

    captures: string[] = [];
    error: any;
    isCaptured: boolean;

    async ngAfterViewInit() {
        await this.setupDevices();
    }

    async setupDevices() {

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: true
                });
                if (stream) {
                    this.video.nativeElement.srcObject = stream;
                    this.video.nativeElement.play();
                    this.error = null;
                } else {
                    this.error = 'You have no output video device';
                }
            } catch (e) {
                this.error = e;
            }
        }
    }

    capture() {
        this.drawImageToCanvas(this.video.nativeElement);
        this.captures.push(this.canvas.nativeElement.toDataURL('image/png'));
        this.isCaptured = true;
    }

    removeCurrent() {
        this.isCaptured = false;
    }

    setPhoto(idx: number) {
        this.isCaptured = true;
        const image = new Image();
        image.src = this.captures[idx];
        this.drawImageToCanvas(image);
    }

    drawImageToCanvas(image: any) {
        this.canvas.nativeElement
            .getContext('2d')
            .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
    }
}
