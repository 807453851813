<div class="container-fluid">
  <app-page-title title="General UI" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card-box">

        <div class="row">
          <div class="col-lg-6">
            <div>
              <h4 class="header-title">Badge</h4>
              <p class="sub-header">
                Badges scale to match the size of the immediate parent element by using relative font sizing and
                <code>em</code> units.
              </p>

              <div>
                <span *ngFor="let badge of uiColor"
                  class="badge badge-{{badge.color}} ml-1">{{badge.color | titlecase}}</span>
              </div>
            </div>
          </div>
          <!--End Badges -->
          <div class="col-lg-6">
            <div>
              <h4 class="header-title">Pill Badges</h4>
              <p class="sub-header">
                Use the <code>.badge-pill</code> modifier class to make badges more rounded.
              </p>

              <div>
                <span *ngFor="let badgePill of uiColor"
                  class="badge badge-pill badge-{{badgePill.color}} ml-1">{{badgePill.value}}</span>
              </div>
            </div>

          </div><!-- End Pill Badges -->
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="mt-5">
              <h4 class="header-title">Badge Lighten</h4>
              <p class="sub-header">
                Badges scale to match the size of the immediate parent element by using relative font sizing and
                <code>em</code> units.
              </p>

              <div>
                <span *ngFor="let badgelight of uiColor"
                  class="badge badge-light-{{badgelight.color}} ml-1">{{badgelight.color | titlecase}}</span>
              </div>
            </div>
          </div><!-- End lighten Badge -->
          <div class="col-lg-6">
            <div class="mt-5">
              <h4 class="header-title">Pill Badges Lighten</h4>
              <p class="sub-header">
                Use the <code>.badge-pill</code> modifier class to make badges more rounded.
              </p>

              <div>
                <span *ngFor="let lightpill of uiColor"
                  class="badge badge-pill badge-light-{{lightpill.color}} ml-1">{{lightpill.value}}</span>
              </div>
            </div>
          </div><!-- End lighten pill badge -->
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="mt-5">
              <h4 class="header-title">Popovers</h4>
              <p class="sub-header">
                Add small overlay content, like those found in iOS, to any element for housing secondary information.
              </p>
              <div class="button-list">
                <button type="button" class="btn btn-secondary waves-effect" placement="top"
                  ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">
                  Popover on top
                </button>
                <button type="button" class="btn btn-secondary waves-effect" placement="right"
                  ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">
                  Popover on right
                </button>
                <button type="button" class="btn btn-secondary waves-effect" placement="bottom"
                  ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">
                  Popover on bottom
                </button>
                <button type="button" class="btn btn-secondary waves-effect" placement="left"
                  ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">
                  Popover on left
                </button>
                <button type="button" class="btn btn-primary waves-effect waves-ligh" [disablePopover]="true"
                  ngbPopover="And here's some amazing content. It's very engaging. Right?"
                  popoverTitle="Dismissible popover">
                  Dismissible popover
                </button>
              </div>
            </div>
          </div><!-- End Popovers -->

          <div class="col-lg-6">
            <div class="mt-5">
              <h4 class="header-title">Tooltips</h4>
              <p class="sub-header">
                Hover over the links below to see tooltips:
              </p>

              <div class="button-list">
                <button type="button" class="btn btn-secondary" placement="top" ngbTooltip="Tooltip on top">
                  Tooltip on top
                </button>
                <button type="button" class="btn btn-secondary" placement="right" ngbTooltip="Tooltip on right">
                  Tooltip on right
                </button>
                <button type="button" class="btn btn-secondary" placement="bottom" ngbTooltip="Tooltip on bottom">
                  Tooltip on bottom
                </button>
                <button type="button" class="btn btn-secondary" placement="left" ngbTooltip="Tooltip on left">
                  Tooltip on left
                </button>
              </div>
            </div>
          </div><!-- End Tooltip -->
        </div>

        <div class="row">

          <!-- Pagination -->
          <div class="col-lg-6">
            <div class="mt-5">
              <h4 class="header-title">Pagination</h4>
              <p class="sub-header">
                Provide pagination links for your site or app with the multi-page pagination component.
              </p>

              <div>
                <h6 class="mb-1">Default Pagination</h6>
                <p class="text-muted font-13">
                  Simple pagination inspired by Rdio, great for apps and search results.
                </p>
                <ngb-pagination [collectionSize]="50"></ngb-pagination>

                <h6 class="mt-2">Rounded Pagination</h6>
                <p class="text-muted font-13">
                  Links are split to each other by adding a class of <code> .pagination-rounded</code>
                </p>
                <ngb-pagination [collectionSize]="50" class="pagination-rounded"></ngb-pagination>

                <h6 class="mb-1">Sizing</h6>
                <p class="text-muted font-13"> Add <code>  .pagination-lg</code> or <code>  .pagination-sm</code> for
                  additional sizes. </p>
                <ngb-pagination [collectionSize]="30" size="lg"></ngb-pagination>

                <h6 class="mb-1">Alignment</h6>
                <p class="text-muted font-13">
                  Change the alignment of pagination components with flexbox utilities.
                </p>
                <ngb-pagination class="d-flex justify-content-center" [collectionSize]="30">
                  <ng-template ngbPaginationPrevious>Previous</ng-template>
                  <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>
                <ngb-pagination class="d-flex justify-content-end" [collectionSize]="30">
                  <ng-template ngbPaginationPrevious>Previous</ng-template>
                  <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div><!-- End Pagination -->

          <div class="col-lg-6">
            <div class="mt-5">
              <h4 class="header-title">Breadcrumb</h4>
              <p class="text-muted font-13">
                Indicate the current page’s location within a navigational hierarchy.
              </p>

              <ol class="breadcrumb">
                <li class="breadcrumb-item active">Home</li>
              </ol>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript: void((0)">Home</a></li>
                <li class="breadcrumb-item active">Library</li>
              </ol>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript: void((0)">Home</a></li>
                <li class="breadcrumb-item"><a href="javascript: void((0)">Library</a></li>
                <li class="breadcrumb-item active">Data</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="jumbotron">
        <h1 class="display-4">Hello, world!</h1>
        <p class="lead">This is a simple hero unit, a simple jumbotron-style component for calling extra attention
          to featured content or information.</p>
        <hr class="my-4">
        <p>It uses utility classes for typography and spacing to space content out within the larger container.
        </p>
        <a class="btn btn-primary btn-lg waves-effect waves-light" href="javascript: void(0);" role="button">Learn
          more</a>
      </div>
    </div> <!-- end col -->
  </div>
  <!-- end row -->
</div>