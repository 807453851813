// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const schema = {
    HTTP_SCHEMA: 'http',
    HTTPS_SCHEMA: 'https',
    PREFIX: '://',
    API_GATEWAY: 'aivisvn.ddns.net:5001/',
    API_GATEWAYS: 'managerdev.ddns.net/',
};

export const environment = {
    production: true,
    WACH_ADDRESS: '8dtT5iBqsPgjj2nd2ueSn3i5q465CmeiobgFPwyUAADf',
    API_URL: schema.HTTPS_SCHEMA + schema.PREFIX + schema.API_GATEWAYS,
    // API_URL: schema.HTTP_SCHEMA + schema.PREFIX + schema.API_GATEWAY,
    firebase: {
        apiKey: 'AIzaSyBQIxMWIyyh6ytQakhVWx3ueBqkmrbzK94',
        authDomain: 'aivis-camera.firebaseapp.com',
        projectId: 'aivis-camera',
        storageBucket: 'aivis-camera.appspot.com',
        messagingSenderId: '201191751459',
        appId: '1:201191751459:web:5577718e3b6b6cffa69696',
        measurementId: 'G-RJN1TN1PV1'
    },
};

export const address = {
    WBNB: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    ACH: '0x70D2a6db88D7671C9A181BF660e6b4EF2188c4F3', // test
    // ACH: '0xfc8137525B30B7d89958e4E9BFC4b15E11435AC2', // main
    BUSD: '0x8516Fc284AEEaa0374E66037BD2309349FF728eA',
    USDT: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    PANCAKE_ROUTER: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
    REMOTE_MONITORING_SERVER_WALLET_ADDRESS: '0xFf86BEfB41d6efB535C05cD686669768CC3F80Ea',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
