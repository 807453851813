import {Component, Input, OnInit} from '@angular/core';
import {NetworkManagementService} from './network-management.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-network-management',
    templateUrl: './network-management.component.html',
    styleUrls: ['./network-management.component.scss']
})
export class NetworkManagementComponent implements OnInit {
    @Input() camId = '';
    listConnection: any = [];
    ethernet = '';
    wifi = '';

    password = '';
    loading: boolean;

    constructor(private networService: NetworkManagementService) {
    }

    ngOnInit() {
        this.getCurrent();
    }

    getCurrent() {
        this.networService.getCurrentConnect(this.camId).subscribe(data => {
            if (data) {
                this.ethernet = data.ethernet || 'Không kết nối';
                this.wifi = data.wifi || 'Không kết nối';
            }
        });

        this.networService.getWifiList(this.camId).subscribe(data => {
            if (data) {
                this.listConnection = data.sort((a: { Strength: number; }, b: { Strength: number; }) => b.Strength - a.Strength) || [];
            }
        });
    }

    rankStrength(x: number) {
        if (x >= 55) {
            return '4 text-success';
        }
        if (x >= 40 && x < 55) {
            return '3 text-primary';
        }
        if (x >= 25 && x < 40) {
            return '2 text-warning';
        }
        if (x >= 10 && x < 25) {
            return '1 text-danger';
        }
        if (x >= 0 && x < 10) {
            return 'outline text-secondary';
        }
        return null;
    }

    connectWifi(ssid: string) {
        if (ssid && this.password) {
            this.loading = true;
            this.networService.connectWifi(this.camId, ssid, this.password).subscribe(data => {
                this.loading = false;
                this.password = '';
                if (data.status !== 'fail') {
                    Swal.fire('Đã kết nối',
                        `Camera đã kết nối với wifi
                        <span class="text-primary">${ssid}</span>`, 'success');
                } else {
                    Swal.fire('Lỗi kết nối',
                        `Không thể kết nối đến wifi
                        <span class="text-primary">${ssid}</span>
                        , vui lòng kiểm tra lại mật khẩu và thử lại`, 'error');
                }
            }, error => this.loading = false);
        }
    }
}
