// const underScoreKey = {
//     id: 'ma_',
//     name: 'ten_'
// };

// const normalKey = {
//     id: 'ma',
//     name: 'ten'
// };

// export { underScoreKey, normalKey };

// export function convertListObjectToMap(list: any, key: string, type: any) {
//     return list[key].reduce((obj, item) => {
//         obj[item[type.id + key]] = item[type.name + key];
//         return obj;
//     }, {});
// }

// export function convertListToObject(list: any, type: any, master: string) {
//     const result = {};
//     list.forEach(elem => {
//         result[elem[type.id + master]] = elem[type.name + master];
//     });
//     return result;
// }

// export function convertListToObjectByKeyValue(list: any, key: string, value: string) {
//     const result = {};
//     list.forEach(elem => {
//         result[elem[key]] = elem[value];
//     });
//     return result;
// }

export function checkMobileMode() {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
}

export function languageByCountry(country: string) {
    switch (country) {
        case 'UK' || 'UK':
            return 'en';
        case 'VN':
            return 'vi';
        default:
            return 'en';
    }
}

export function convertStringBase64ToBinary(dataURI: string) {
    const raw = window.atob(dataURI);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));
    for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
    }
    return array;
}

export function convertDataURIToBinary(dataURI: any) {
    const BASE64_MARKER = ';base64,';
    const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    const base64 = dataURI.substring(base64Index);
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));
    for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
    }
    return array;
}

export function base64EncodeUnicode(str: string) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode(Number('0x' + p1));
        }));
}

export function base64DecodeUnicode(str: string) {
    // Going backwards: from byte stream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

// export function toSlug(str: string, seperator?: string) {
//     const symbol = seperator || '-';
//     // Chuyển hết sang chữ thường
//     str = str.toLowerCase();
//     // xóa dấu
//     str = str.replace(/([àáạảãâầấậẩẫăằắặẳẵ])/g, 'a');
//     str = str.replace(/([èéẹẻẽêềếệểễ])/g, 'e');
//     str = str.replace(/([ìíịỉĩ])/g, 'i');
//     str = str.replace(/([òóọỏõôồốộổỗơờớợởỡ])/g, 'o');
//     str = str.replace(/([ùúụủũưừứựửữ])/g, 'u');
//     str = str.replace(/([ỳýỵỷỹ])/g, 'y');
//     str = str.replace(/(đ)/g, 'd');
//     // Xóa ký tự đặc biệt
//     str = str.replace(/([^0-9a-z-\s])/g, '');
//     // Xóa khoảng trắng thay bằng ký tự -
//     str = str.replace(/(\s+)/g, symbol);
//     // xóa phần dự - ở đầu
//     str = str.replace(/^-+/g, '');
//     // xóa phần dư - ở cuối
//     str = str.replace(/-+$/g, '');
//     // return
//     return str;
// }

// export function formatBytes(bytes, decimals = 2) {
//     if (bytes === 0) {
//         return '0 Bytes';
//     }
//     const k = 1024;
//     const dm = decimals < 0 ? 0 : decimals;
//     const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
//     const i = Math.floor(Math.log(bytes) / Math.log(k));
//     return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
// }
