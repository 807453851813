import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {CommandURL} from './app/shared/constant/ManageURLCommand';
import {CookieService} from 'src/app/core/services/cookie.service';
import {TranslateService} from '@ngx-translate/core';
import {languageByCountry} from './app/shared/utils/convert.util';

@Injectable({providedIn: 'root'})
export class EnvService {
    public apiUrl = '';

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private translate: TranslateService
    ) {
    }

    setEnvironment(env: string, country: string) {
        this.cookieService.setCookie('environment', env, 30);
        this.cookieService.setCookie('lang', languageByCountry(country), 30);
        this.translate.setDefaultLang(languageByCountry(country));
        this.apiUrl = env;
    }

    getEnvironment() {
        return this.apiUrl;
    }

    getMyIp() {
        return this.http.get<any>(environment.API_URL + CommandURL.GET_IP_PUBLIC);
    }

    getMylocation(ip: string) {
        const params = new HttpParams().set('ipPublic', ip);
        return this.http.get<any>(environment.API_URL + CommandURL.GET_DOMAIN_BACKEND, {params});
    }
}


