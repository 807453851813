import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
// @ts-ignore
import videojs from 'video.js/dist/video.js';
import {AuthenticationService} from "../../../core/services/auth.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-vjs-player',
    templateUrl: './vjs-player.component.html',
    styleUrls: ['./vjs-player.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class VjsPlayerComponent implements OnInit, OnDestroy {
    @ViewChild('target', {static: true}) target: ElementRef;
    // see options: https://github.com/videojs/video.js/blob/maintutorial-options.html
    @Input() options: {
        fluid: boolean,
        aspectRatio: string,
        autoplay: boolean,
        sources: {
            src: string,
            type: string,
        }[]
    };
    @Output() endedVideo = new EventEmitter();

    player: videojs.Player;
    constructor(
        private elementRef: ElementRef,
        private authenticationService: AuthenticationService,
        private router: Router
    ) {

    }

    ngOnInit() {
        const endedVideo = this.endedVideo;
        this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
            // console.log('onPlayerReady', this);

            // @ts-ignore
            this.on('ended', () => {
                endedVideo.emit('video is done!');
            });

            // this.nuevo({
            //     zoonInfo: true,
            //     zoomWheel: true
            // });

        });
    }

    ngOnDestroy() {
        // destroy player
        if (this.player) {
            this.player.dispose();
        }
    }

    errorHandler(e: ErrorEvent) {
        if (e) {
            this.authenticationService.logout();
            this.router.navigate(['/account/login']);
        }

    }
}
