import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uploads',
  templateUrl: './uploads.component.html',
  styleUrls: ['./uploads.component.scss']
})

/**
 * Uploads component - handling the form upload with navbar and content
 */
export class UploadsComponent implements OnInit {

  // bread crumb items
  breadCrumbItems: Array<any>;

  constructor() { }

  ngOnInit() {
    // tslint:disable-next-line: max-line-length
    this.breadCrumbItems = [{ label: 'Aivis', path: '/' }, { label: 'Forms', path: '/' }, { label: 'File Uploads', path: '/', active: true }];

  }

}
