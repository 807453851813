import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

import {Inbox} from './rightsidebar.model';

import {inboxData} from './data';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-rightsidebar',
    templateUrl: './rightsidebar.component.html',
    styleUrls: ['./rightsidebar.component.scss']
})
export class RightsidebarComponent implements OnInit {
    @Input() mode: any = null;
    // vis-timeline
    @Output() clickGetRecordVideo = new EventEmitter();
    @Input() listTimeData = [];
    @Input() ngDateChoose: NgbDate;

    inboxData: Inbox[];
    

    constructor() {
    }

    ngOnInit() {
        /**
         * fetches data
         */
        this._fetchData();

    }

    /**
     * Hide the sidebar
     */
    public hide() {
        document.body.classList.remove('right-bar-enabled');
    }

    getRecordVideo(data: any) {
        this.clickGetRecordVideo.emit(data);
        this.hide();
    }

    /**
     * fetches the inbox value
     */
    private _fetchData() {
        this.inboxData = inboxData;
    }
}
