import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupSettingComponent } from './group-setting/group-setting.component';
import {UIModule} from '../../shared/ui/ui.module';
import {TranslateModule} from '@ngx-translate/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [GroupSettingComponent],
  imports: [
    CommonModule,
    UIModule,
    TranslateModule,
    NgSelectModule,
    NgbNavModule,
    FormsModule
  ]
})
export class SettingsModule { }
