import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgbCollapseModule, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {ClickOutsideModule} from 'ng-click-outside';

import {UIModule} from '../shared/ui/ui.module';
import {LayoutComponent} from './layout.component';
import {TopbarComponent} from './topbar/topbar.component';
import {FooterComponent} from './footer/footer.component';
import {RightsidebarComponent} from './rightsidebar/rightsidebar.component';
import {NavbarComponent} from './navbar/navbar.component';
import {ListCameraComponent} from '../pages/list-camera/list-camera.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {QRCodeModule} from 'angularx-qrcode';
import {TranslateModule} from '@ngx-translate/core';
import {MobileTimelineComponent} from '../pages/vis-timeline/mobile-timeline/mobile-timeline.component';

@NgModule({
    declarations: [
        LayoutComponent,
        TopbarComponent,
        FooterComponent,
        RightsidebarComponent,
        NavbarComponent,
        ListCameraComponent,
        MobileTimelineComponent
    ],
    exports: [
        RightsidebarComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgbDropdownModule,
        NgbCollapseModule,
        ClickOutsideModule,
        UIModule,
        FormsModule,
        SharedModule,
        QRCodeModule,
        ReactiveFormsModule,
        TranslateModule
    ]
})
export class LayoutsModule {
}
