<div class="container-fluid">
    <app-page-title [breadcrumbItems]="breadCrumbItems"
                    [title]="'Camera setting group'"></app-page-title>


    <ul #nav="ngbNav" class="nav-tabs" ngbNav>
        <li ngbNavItem>
            <a ngbNavLink>Group list</a>
            <ng-template ngbNavContent>
                <div class="row">
                    <div class="col-md-12">

                        <input [(ngModel)]="groupName" class="form-control" placeholder="enter group name ..."
                               type="text">
                        <button (click)="onSave()" [disabled]="!groupName"
                                class="btn btn-primary mt-3"> {{'group.add' | translate}}</button>

                        <table class="table table-bordered mt-3">
                            <thead class="thead-light">
                            <tr>
                                <th>Sr.No</th>
                                <th>Group Name</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of groupList; let i = index">
                                <td>{{i + 1}}</td>
                                <td>{{item?.name}}</td>
                                <td>
                                    <button (click)="onDelete(item)"
                                            class="btn btn-danger">{{'group.delete' | translate}}</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </ng-template>
        </li>
        <li ngbNavItem>
            <a ngbNavLink>Group config</a>
            <ng-template ngbNavContent>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <ng-select (change)="configGroup($event)" [(ngModel)]="groupSelected"
                                       [items]="groupList"
                                       bindLabel="name"
                                       bindValue="id" placeholder="choose group ..."></ng-select>
                        </div>

                        <table class="table table-bordered mt-3">
                            <thead class="thead-light">
                            <tr>
                                <th>Sr.No</th>
                                <th>User Name</th>
                                <th>
                                    Add to group
                                    <!--                                    <div class="custom-control custom-checkbox mt-2">-->
                                    <!--                                        <input [id]="'col'" class="custom-control-input" type="checkbox">-->
                                    <!--                                        <label [for]="'col'" class="custom-control-label"></label>-->
                                    <!--                                    </div>-->
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of listCamera; let i = index">
                                <td>{{i + 1}}</td>
                                <td>{{(item.setting ? item.setting.name : ('notify.noname' | translate)) | titlecase}}</td>
                                <td>
                                    <div class="custom-control custom-checkbox mt-2">
                                        <input (change)="checked($event, item)"
                                               [disabled]="!groupSelected" [id]="i + 'col'" class="custom-control-input"
                                               type="checkbox">
                                        <label [for]="i + 'col'" class="custom-control-label"></label>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!--                <div class="row justify-content-center mt-3">-->
                <!--                    <button class="btn btn-sm btn-primary my-3" [disabled]="!(groupSelected && this.listCamera > 0)">-->
                <!--                        {{'save' | translate}} <i class="ml-1 fas fa-save"></i>-->
                <!--                    </button>-->
                <!--                </div>-->
            </ng-template>
        </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
