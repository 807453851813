import {Component, OnDestroy, OnInit} from '@angular/core';
import {PagesService} from '../../shared/service/pages.service';
import {Camera} from '../../shared/model/main';
import Swal from 'sweetalert2';
import {checkMobileMode} from '../../shared/utils/convert.util';

@Component({
    selector: 'app-camera-monitor',
    templateUrl: './camera-monitor.component.html',
    styleUrls: ['./camera-monitor.component.scss']
})
export class CameraMonitorComponent implements OnInit, OnDestroy {
    videoLoadingImg = '/assets/images/camera/loading-gif.gif';
    videoNotFoundImg = '/assets/images/camera/no-signal.gif';

    listCamera: Array<any> = new Array<any>();
    loading = false;
    col = 1;
    rows = [];

    turnServer = 'aivisvn.ddns.net:3478';
    isMobileMode: boolean;
    public base64token = 'Wait connect';
    public base64tokenRes = 'Wait connect';
    arrConnected: any = [];
    isShakeHands: any = [];
    listFake = [];
    isFullScreen = false;
    private timerInterval: number | any;
    private refreshCount = 0;

    constructor(private pageService: PagesService) {
        this.isMobileMode = checkMobileMode();
    }

    ngOnInit() {
        let timerInterval: any;
        (Swal as any).fire({
            // title: 'Camera 24/7 sẵn sàng trong',
            html: 'Camera 24/7 sẵn sàng trong <strong></strong> giây.',
            timer: 10000,
            onBeforeOpen: () => {
                clearInterval(timerInterval);
                Swal.showLoading();
                timerInterval = setInterval(() => {
                    if (Swal) {
                        (Swal as any).getContent().querySelector('strong').textContent =
                            ((Swal as any).getTimerLeft() / 1000 + '').substring(0, ((Swal as any).getTimerLeft() / 1000 + '').lastIndexOf('.'));
                    } else {
                        clearInterval(timerInterval);
                    }
                }, 100);
            },
            onClose: () => {
                clearInterval(timerInterval);
            }
        }).then((result: { dismiss: Swal.DismissReason; }) => {
            if (
                result.dismiss === Swal.DismissReason.timer
            ) {
                this.fetchCameraList();
            }
        });
    }

    ngOnDestroy() {
        clearInterval(this.timerInterval);
        this.arrConnected.forEach((x: any) => x.obj.close());
    }

    fetchCameraList() {
        this.pageService.fetchCameraList().subscribe(data => {
                const res = data.cams || [];
                // const res = [
                //     {cameraId: '1awq'},
                //     {cameraId: '2awq'},
                //     {cameraId: '1awqe'},
                //     {cameraId: '4awq'},
                //     // {cameraId: '5awq'},
                //     // {cameraId: '6awq'},
                //     // {cameraId: '7awq'},
                //     // {cameraId: '8awq'},
                //     // {cameraId: '9awq'},
                //     // {cameraId: '10awq'},
                // ];
                if (res && res.length > 0) {
                    const sqrt = Math.sqrt(res.length);
                    const num = Math.floor(sqrt);
                    this.col = sqrt === num ? num : num + 1;

                    while (res.length) {
                        this.listCamera.push(res.splice(0, this.col));
                    }
                    // @ts-ignore
                    this.rows = Array(this.listCamera.length).fill().map((x, i) => i);

                    // console.log('sqrt', sqrt);
                    // console.log('col', this.col);
                    // console.log('rows', this.rows);
                    // console.log('listCamera', this.listCamera);

                    if (this.listCamera[this.listCamera.length - 1].length + 1 <= this.col) {
                        // @ts-ignore
                        this.listFake = Array(this.col - this.listCamera[this.listCamera.length - 1].length).fill().map((x, i) => i);
                    }
                    this.isShakeHands = this.listCamera.map(x => x.map((y: any) => false));
                    this.listCamera.forEach((x, i) => x.forEach((y: Camera, z: number) => this.shakeHands(y, i, z, false)));
                }
            }, error => this.loading = false
        );
    }

    shakeHands(cameraObj: Camera, i: number, index: number, isRefresh: boolean) {
        this.loading = true;
        this.pageService.getTurnCredential().subscribe(data => {
            if (data) {
                // data.exception === 'Unauthorized'
                if (data.message === 'timeout' || data === 'Error: Response Timeout') {
                    this.disconectCamera(cameraObj, i, index);
                    return;
                }
                this.turnServer = data.turnServer;
                const pcConfig = {
                    iceServers: [
                        {
                            urls: 'stun:' + data.turnServer
                        },
                        // {
                        //     urls: 'turn:' + data.turnServer,
                        //     username: data.username,
                        //     credential: data.password,
                        // }
                    ]
                };

                const pc = new RTCPeerConnection(pcConfig);
                const log = (msg: string) => {

                    // document.getElementById('log' + cameraObj.cameraId).innerHTML += msg + '<br>';
                    const arr = [];
                    arr.push([cameraObj.cameraId, msg, this.base64token]);
                    console.table(arr);

                    if (msg === 'disconnected') {
                        this.retryConnect(cameraObj, i, index);
                    }
                };

                pc.ontrack = (event) => {
                    const el = document.createElement(event.track.kind);
                    // @ts-ignore
                    el.srcObject = event.streams[0];
                    // @ts-ignore
                    el.autoplay = true;
                    // @ts-ignore
                    el.controls = true;
                    // @ts-ignore
                    el.muted = true;

                    if (event.track.kind === 'audio') {
                        // @ts-ignore
                        el.controls = false;
                        // @ts-ignore
                        el.muted = true;
                        // el.play();
                    }

                    // @ts-ignore
                    document.getElementById(cameraObj.cameraId).appendChild(el);

                    setTimeout(() => {
                        this.setAttributeVideo(cameraObj.cameraId, 'width', '100%');

                        if (!this.isMobileMode) {
                            // @ts-ignore
                            document.getElementById(cameraObj.cameraId).children[0].controls = false;
                            // @ts-ignore
                            document.getElementById(cameraObj.cameraId).children[0].style.pointerEvents = 'none';
                        }

                    }, 800);
                };

                pc.oniceconnectionstatechange = e => log(pc.iceConnectionState);
                pc.onicecandidate = event => {
                    if (event.candidate === null) {
                        // console.log('base64 json', JSON.stringify(pc.localDescription));
                        this.base64token = btoa(JSON.stringify(pc.localDescription));

                        this.pageService.requestStreamSignalWithTurn(cameraObj.cameraId, this.base64token, this.turnServer)
                            .subscribe(res => {
                                if (res.token) {
                                    if (isRefresh) {
                                        this.disconectCamera(cameraObj, i, index);
                                        this.refreshCount = 0;
                                    }

                                    this.base64tokenRes = res.token;
                                    // console.log('base64tokenRes', this.base64tokenRes);

                                    // start ontrack connect
                                    pc.setRemoteDescription(new RTCSessionDescription(JSON.parse(atob(res.token))));

                                    if (this.arrConnected.find((x: any) => x.id === cameraObj.cameraId)) {
                                        // @ts-ignore
                                        this.arrConnected.splice( this.arrConnected.indexOf(this.arrConnected.find(x => x.id === cameraObj.cameraId)), 1);
                                    }
                                    // this.arrConnected.push({obj: pc, id: i.toString() + index.toString()});
                                    // @ts-ignore
                                    this.arrConnected.push({obj: pc, id: cameraObj.cameraId, connected: true});
                                    this.isShakeHands[i][index] = true;
                                    this.loading = false;
                                    clearInterval(this.timerInterval);
                                }
                            }, error => {
                                // @ts-ignore
                                this.arrConnected.push({obj: pc, id: cameraObj.cameraId, connected: false});
                                this.loading = false;
                                // data.exception === 'Unauthorized'
                                // if (error === 'timeout' || error === 'Error: Response Timeout') {
                                //     // this.retryConnect(cameraObj, i, index);
                                //     this.disconectCamera(cameraObj, i, index);
                                //     return;
                                // }
                            });
                    }

                };

                // Offer to receive 1 audio, and 1 video track
                pc.addTransceiver('video', {direction: 'sendrecv'});
                pc.addTransceiver('audio', {direction: 'sendrecv'});
                pc.createOffer().then(d => pc.setLocalDescription(d)).catch(log);
            }
        }, error => {
            this.loading[index] = false;

            // if (error === 'timeout' || error === 'Error: Response Timeout') {
            //     this.disconectCamera(cameraObj, i, index);
            //     return;
            // }
        });
    }

    retryConnect(cameraObj: Camera, i: number, index: number) {
        this.timerInterval = setInterval(() => {
            console.log(cameraObj.cameraId);
            if (this.refreshCount === 3) {
                clearInterval(this.timerInterval);
            }
            this.refreshCamera(cameraObj, i, index);
            this.refreshCount++;
        }, 10000);
    }

    refreshCamera(cameraObj: Camera, i: number, index: number) {
        // this.disconectCamera(cameraObj, i, index);
        this.shakeHands(cameraObj, i, index, true);
    }

    disconectCamera(cameraObj: Camera, i: number, index: number) {
        const elem = document.getElementById(cameraObj.cameraId);

        if (elem) {
            console.log(elem.childNodes);
            // @ts-ignore
            this.arrConnected.find(x => x.id === cameraObj.cameraId).obj.close();
            this.isShakeHands[i][index] = false;
            elem.removeChild(elem.childNodes[1]);
            elem.removeChild(elem.childNodes[1]);
        }

    }

    setAttributeVideo(id: string, attributeName: string, value: string) {
        // @ts-ignore
        document.getElementById(id).children[0].setAttribute(attributeName, value);
    }

    onClickShowFullScreen(ele: any, evt?: any) {
        // if (this.isFullScreen) {
        //     this.isFullScreen = false;
        //     document.exitFullscreen().then(r => console.log('r', r));
        // } else {
        evt.preventDefault();
        this.requestFullscreen(ele);
        setTimeout(() => {
            this.arrConnected.forEach((x: any) => this.setAttributeVideo(x.cameraId, 'width', '100%'));
        }, 500);
        // console.log('arrConnected', this.arrConnected);
        console.log('isShakeHands', this.isShakeHands);
        // }
    }

    requestFullscreen(ele: HTMLElement & { mozRequestFullScreen(): Promise<void>; webkitRequestFullscreen(): Promise<void>; msRequestFullscreen(): Promise<void>; }) {
        // this.isFullScreen = true;
        // @ts-ignore
        document.getElementById('cameraMonitor').classList.add('height-100');
        const docEl = ele as HTMLElement & {
            mozRequestFullScreen(): Promise<void>;
            webkitRequestFullscreen(): Promise<void>;
            msRequestFullscreen(): Promise<void>;
        };
        if (docEl.requestFullscreen) {
            docEl.requestFullscreen();
        } else if (docEl.mozRequestFullScreen) {
            /* Firefox */
            docEl.mozRequestFullScreen();
        } else if (docEl.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            docEl.webkitRequestFullscreen();
        } else if (docEl.msRequestFullscreen) {
            /* IE/Edge */
            docEl.msRequestFullscreen();
        }
    }
}
