import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BlockchainService} from "../core/services/blockchain.service";
import {
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter, SolletWalletAdapter,
    TorusWalletAdapter, TrustWalletAdapter
} from "@solana/wallet-adapter-wallets";
import {ConnectionStore, WalletStore} from "@heavy-duty/wallet-adapter";
import {WalletAdapterNetwork} from "@solana/wallet-adapter-base";

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit {
    balances: any[]; // MetaMask
    showMobileMenu = false;
    isShow = false;
    enable = true;

    refershListCamera = false;


    constructor(
        private blockchainService: BlockchainService,
        private _connectionStore: ConnectionStore,
        private _walletStore: WalletStore,
        private router: Router,
        private translate: TranslateService
    ) {

        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                // console.log('route', val.url);
                if (val.url.includes('/list-camera') || val.url === '/') {
                    if (val.url.includes('?')) {
                        this.refershListCamera = true;
                    }
                    this.isShow = true;
                } else {
                    this.isShow = false;
                }

                if (val.url === '/account/login') {
                    this.enable = false;
                }
            }
        });

        this.balances = [];

        // solana
        this._connectionStore.setEndpoint('https://api.devnet.solana.com');
        this._walletStore.setAdapters([
            new PhantomWalletAdapter(),
            new SlopeWalletAdapter(),
            // new SolflareWalletAdapter(),
            new TorusWalletAdapter(),
            new LedgerWalletAdapter(),
            new TrustWalletAdapter({ network: WalletAdapterNetwork.Mainnet }),
            new SolletWalletAdapter({ network: WalletAdapterNetwork.Devnet }),
            // new WalletConnectWalletAdapter({
            //   network: WalletAdapterNetwork.Mainnet,
            //   options: {
            //     relayUrl: 'wss://relay.walletconnect.com',
            //     // example WC dapp project ID
            //     projectId: 'e899c82be21d4acca2c8aec45e893598',
            //     metadata: {
            //       name: 'Example Dapp',
            //       description: 'Example Dapp',
            //       url: 'https://github.com/solana-labs/wallet-adapter',
            //       icons: ['https://avatars.githubusercontent.com/u/35608259?s=200'],
            //     },
            //   },
            // }),
        ]);

    }

    async ngOnInit() {
        this.balances = [
            await this.blockchainService.getTokenBalance('ACH'),
            await this.blockchainService.getETHBalance(),
        ];
    }

    ngAfterViewInit() {
        document.body.classList.remove('authentication-bg');
    }

    useLanguage(language: any) {
        this.translate.use(language.id);
    }

    /**
     * on settings button clicked from topbar
     */
    onSettingsButtonClicked() {
        document.body.classList.toggle('right-bar-enabled');
    }

    /**
     * On mobile toggle button clicked
     */
    onToggleMobileMenu() {
        this.showMobileMenu = !this.showMobileMenu;
    }

    componentAdded(e: any) {
        // console.log('componentAdded', e);
    }

    componentRemoved(e: any) {
        // console.log('componentRemoved', e);
    }
}
