import {Component, OnInit} from '@angular/core';
import {EnvService} from '../env.service';
import {environment} from '../environments/environment';
import {CookieService} from './core/services/cookie.service';
import {first, shareReplay} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-aivis',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        private envService: EnvService,
        private cookieService: CookieService,
        private translate: TranslateService
    ) {
    }


    ngOnInit(): void {
        // window.onbeforeunload = (evt) => {
        //     const message = 'Are you sure you want to leave?';
        //     if (typeof evt === 'undefined') {
        //         evt = window.event;
        //     }
        //     if (evt) {
        //         evt.returnValue = message;
        //     }
        //     return message;
        // };


        this.envService.getMyIp().pipe(first()).subscribe(res => {
            if (res && res.ipPublic) {
                const localStorageIp = this.cookieService.getCookie('ip') || '';
                const environmentStorage = this.cookieService.getCookie('environment') || '';
                const lang = this.cookieService.getCookie('lang') || '';

                if (!localStorageIp) {
                    this.cookieService.setCookie('ip', res.ipPublic, 30);
                }

                if (!environmentStorage || !lang) {
                    this.getBackendDomain(res.ipPublic);
                } else {
                    if (localStorageIp && localStorageIp !== res.ipPublic) {
                        this.cookieService.setCookie('ip', res.ipPublic, 30);
                        this.getBackendDomain(res.ipPublic);
                    }
                }
                this.translate.setDefaultLang(lang || 'vi');
            }
        }, error => {
            this.cookieService.setCookie('ip', '', 30);
            this.envService.setEnvironment('', 'vi');
        });
    }

    getBackendDomain(ipPublic: string) {
        this.envService.getMylocation(ipPublic).pipe(shareReplay(1)).subscribe(data => {
            if (data) {
                this.envService.setEnvironment(data.backendDomain, data.country);
                // this.envService.setEnvironment('managerdev.ddns.net', data.country);
            }
        }, error => this.envService.setEnvironment('', 'vi'));
    }
}
