import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CookieService} from '../../core/services/cookie.service';
import {CommandURL} from '../../shared/constant/ManageURLCommand';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient,
              private cookieService: CookieService) { }

  getListGroup() {
    return this.http.post<any>(CommandURL.GET_LIST_GROUP, {});
  }

  createGroup(name: string) {
    return this.http.post<any>(CommandURL.CREATE_GROUP, {name});
  }

  deleteGroup(groupId: string) {
    return this.http.post<any>(CommandURL.DELETE_GROUP, {groupId});
  }

  addCamToGroup(cameraId: string, groupId: string) {
    return this.http.post<any>(CommandURL.ADD_CAM_TO_GROUP, {cameraId, groupId});
  }

  removeCamOutOfGroup(cameraId: string, groupId: string) {
    return this.http.post<any>(CommandURL.REMOVE_CAM_OUT_OF_GROUP, {cameraId, groupId});
  }
}
