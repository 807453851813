<div class="container-fluid">
    <app-page-title [breadcrumbItems]="breadCrumbItems" title="Advanced Table"></app-page-title>

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="header-title">Advanced Data Table</h4>
                    <p class="text-muted font-13 mb-4">

                    </p>
                    <div class="row mb-md-2">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="tickets-table_length"><label
                                    class="d-inline-flex align-items-center">Show
                                <select [(ngModel)]="service.pageSize" aria-controls="tickets-table" class="custom-select custom-select-sm form-control form-control-sm mx-2"
                                        name="tickets-table_length"
                                        name="pageSize">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select> entries</label></div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_filter text-md-right" id="tickets-table_filter"><label
                                    class="d-inline-flex align-items-center">Search:
                                <input [(ngModel)]="service.searchTerm" aria-controls="tickets-table" class="form-control form-control-sm ml-2"
                                       name="searchTerm" type="text"></label></div>
                        </div>
                        <!-- End search -->
                    </div>
                    <!-- Table -->
                    <table class="table datatables dt-responsive nowrap" id="basic-datatable">
                        <thead>
                        <tr>
                            <th (sort)="onSort($event)" sortable="name">Name</th>
                            <th (sort)="onSort($event)" sortable="position">Position</th>
                            <th (sort)="onSort($event)" sortable="office">Office</th>
                            <th (sort)="onSort($event)" sortable="age">Age</th>
                            <th (sort)="onSort($event)" sortable="date">Start date</th>
                            <th (sort)="onSort($event)" sortable="salary">Salary</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let table of tables$ | async" tr>
                            <td>
                                <ngb-highlight [result]="table.name" [term]="service.searchTerm"></ngb-highlight>
                            </td>
                            <td>
                                <ngb-highlight [result]="table.position" [term]="service.searchTerm"></ngb-highlight>
                            </td>
                            <td>
                                <ngb-highlight [result]="table.office" [term]="service.searchTerm"></ngb-highlight>
                            </td>
                            <td>
                                <ngb-highlight [result]="table.age" [term]="service.searchTerm"></ngb-highlight>
                            </td>
                            <td>
                                <ngb-highlight [result]="table.date" [term]="service.searchTerm"></ngb-highlight>
                            </td>
                            <td>
                                <ngb-highlight [result]="table.salary" [term]="service.searchTerm"></ngb-highlight>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <!-- End table -->
                    <div class="row justify-content-md-between align-items-md-center mt-2">
                        <div class="col-sm-12 col-md-5">
                            <div aria-live="polite" class="dataTables_info mb-2" id="tickets-table_info" role="status">
                                Showing
                                {{service.startIndex + 1}} to
                                {{service.endIndex}} of {{service.totalRecords}}
                                entries
                            </div>
                        </div>
                        <!-- Pagination -->
                        <!-- <div class="col-sm-12 col-md-5">
                            <div class="text-md-right float-md-right pagination-rounded">
                                <ngb-pagination [(page)]="service.page" [collectionSize]="total$ | async"
                                                [pageSize]="service.pageSize">
                                </ngb-pagination>
                            </div>
                        </div> -->
                        <!-- End Pagination -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
