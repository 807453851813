<main>
    <section>

        <select class="form-control text-black" [hidden]="true" [ngModel]="walletName$ | async"
                (ngModelChange)="onSelectWallet($event)">
            <option [ngValue]="null">Not selected</option>
            <option *ngFor="let wallet of wallets$ | async" [ngValue]="wallet.adapter.name">
                {{ wallet.adapter.name }} ({{ wallet.readyState }})
            </option>
        </select>

        <p>
            Selected provider: {{ walletName$ | async }}
            <ng-container *ngIf="ready$ | async">(READY)</ng-container>
        </p>
        <p>Wallet Key: {{ publicKey$ | async }}</p>
        <!--        <button class="btn btn-primary" (click)="onConnect()"-->
        <!--                *ngIf=" (connected$ | async) === false && (walletName$ | async) !== null"-->
        <!--                [disabled]="(ready$ | async) === false">-->
        <!--            Connect-->
        <!--        </button>-->
        <button class="btn btn-primary" (click)="onSelectWallet('Phantom')"
                *ngIf="(connected$ | async) === false"
                [disabled]="(ready$ | async) === false">
            Connect
        </button>
        <button class="btn btn-primary" (click)="onDisconnect()" *ngIf="connected$ | async">
            Disconnect
        </button>
    </section>

    <!--    <section *ngIf="publicKey$ | async as publicKey">-->
    <!--        <h2>Transaction</h2>-->

    <!--        <div>-->
    <!--            <label>Recipient</label>-->
    <!--            <input type="text" [(ngModel)]="recipient"/>-->
    <!--        </div>-->

    <!--        <div>-->
    <!--            <label>Lamports</label>-->
    <!--            <input type="number" [(ngModel)]="lamports"/>-->
    <!--        </div>-->

    <!--        &lt;!&ndash; <button class="btn btn-primary" (click)="createTokenAccount(publicKey)">Get Token Account</button> &ndash;&gt;-->
    <!--        <button class="btn btn-primary" (click)="onSendWACH(publicKey)">Send WACH</button>-->
    <!--        <button class="btn btn-primary" (click)="onSendTransaction(publicKey)">Send Transaction</button>-->
    <!--        <button class="btn btn-primary" (click)="onSignTransaction(publicKey)">Sign Transaction</button>-->
    <!--        <button class="btn btn-primary" (click)="onSignAllTransactions(publicKey)">-->
    <!--            Sign All Transactions-->
    <!--        </button>-->
    <!--        <button class="btn btn-primary" (click)="onSignMessage()">Sign Message</button>-->
    <!--    </section>-->
</main>