
<!--<button class="btn btn-primary" (click)="transfer()">transfer 10 ACH</button>-->

<a class="dropdown-item notify-item" href="javascript:void(0);">
    <i class="remixicon-lock-line"></i>
    <button class="btn btn-primary" (click)="connectWallet()">connectWallet</button>
</a>


<a class="dropdown-item notify-item" href="javascript:void(0);">
    <i class="remixicon-lock-line"></i>
    <p class="btn-text"
    >{{ balances[0] | number: "1.0-3" }} ACH</p
    >
</a>

<a class="dropdown-item notify-item" href="javascript:void(0);">
    <i class="remixicon-lock-line"></i>
    <p class="btn-text"
    >{{ balances[1] | number: "1.0-3" }} BNB</p
    >
</a>