import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {WebSocketService} from '../../core/services/web-socket.service';
import {CommandURL} from '../../shared/constant/ManageURLCommand';
import {AuthenticationService} from '../../core/services/auth.service';

const CHAT_URL = 'wss://managerdev.ddns.net';

@Injectable({
    providedIn: 'root'
})
export class CameraRemoteService {
    public messages: Subject<any>;
    address: any = 'wss://managerdev.ddns.net/remote-monitoring/watcher/frontend-rpc-server-ws/5?authorization=';

    connected: Subscription;
    isConnected = false;

    private apiData = new BehaviorSubject<any>(null);
    public apiData$ = this.apiData.asObservable();


    constructor(private http: HttpClient,
                private auth: AuthenticationService,
               ) {
    }

    setData(data: any) {
        this.apiData.next(data);
    }


    initSupervisor() {
        return this.http.post<any>(CommandURL.INIT_SUPERVISOR, {});
    }

    wantReceiveOrder(orderType: string, param: number) {
        return this.http.post<any>(CommandURL.WANT_RECEIVE_ORDER, {orderType, param});
    }

    initSession() {
        return this.http.post<any>(CommandURL.INIT_SESSION, {});
    }

    order(cameraId: string) {

        const formD = new Date().getTime() + 30000;
        const toD = formD + 30000;

        console.log('startTime', this.toDateStr(new Date(formD)));
        console.log('startTime', this.toDateStr(new Date(toD)));

        const json = {
            cameraId,
            startTime: this.toDateStr(new Date(formD)),
            endTime: this.toDateStr(new Date(toD)),
            timezone: 'Asia/Ho_Chi_Minh',
            prioritize: '',
            type: 'C',
            language: 'vi-VN',
            pricePerHour: '1',
            transactionHash: '123456',
            address: '77 Nguyen Suy, Phuong Tan Quy, Quan Tan Phu, HCM',
            phoneNumber: '+84985180822',
            policePhoneNumber: '+84113',
            firefighterPhoneNumber: '+84114',
            hospitalPhoneNumber: '+84115',
            normalEvent: 'Khach vao mua hang',
            abnormalEvent: 'Be khoa xe may',
            note: 'khong co gi'
        };
        return this.http.post<any>(CommandURL.ORDER, json);
    }

    fetchListCamHistory(cameraId: string) {
        return this.http.post<any>(CommandURL.HISTORY_STREAMS, {cameraId, numOfVideo: 5});
    }

    toDateStr(d: any) {
        return [d.getFullYear(),
                (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1),
                d.getDate() < 10 ? '0' + d.getDate() : d.getDate()].join('-') + ' ' +
            [d.getHours() < 10 ? '0' + d.getHours() : d.getHours(),
                d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes(),
                d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds()].join(':');
    }
}
