import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';

import {AuthenticationService} from '../../core/services/auth.service';
import {CookieService} from '../../core/services/cookie.service';
import {TranslateService} from "@ngx-translate/core";


@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
    @Input() balances: any[] = [];
    notificationItems: Array<any>;
    languages: Array<{
        id: any,
        flag?: string,
        name: string
    }>;
    selectedLanguage: {
        id: any,
        flag?: string,
        name: string
    } | any;

    openMobileMenu: boolean;
    @Output() settingsButtonClicked = new EventEmitter();
    @Output() mobileMenuButtonClicked = new EventEmitter();
    @Output() changeLanguageClicked = new EventEmitter();
    username: any = '';
    dropdownOpen: any;

    constructor(private router: Router,
                private authService: AuthenticationService,
                private cookieService: CookieService,
    ) {
        setTimeout(() => {
            this.username = this.authService.currentUser().username;
        }, 500);
    }

    ngOnInit() {
        // get the notifications
        // this._fetchNotifications();

        // get the language
        this._fetchLanguages();

        this.openMobileMenu = false;
    }


    /**
     * Change the language
     * @param language language
     */
    changeLanguage(language: any) {
        this.selectedLanguage = language;
        this.changeLanguageClicked.emit(language);
    }

    /**
     * Toggles the right sidebar
     */
    toggleRightSidebar() {
        this.settingsButtonClicked.emit();
    }

    /**
     * Toggle the menu bar when having mobile screen
     */
    toggleMobileMenu(event: any) {
        event.preventDefault();
        this.openMobileMenu = !this.openMobileMenu;
        this.mobileMenuButtonClicked.emit();
    }

    /**
     * Logout the user
     */
    logout() {
        this.authService.logout();
        this.router.navigate(['/account/login']);
    }

    /**
     * Fetches the supported languages
     */
    _fetchLanguages() {
        this.languages = [
            {
                id: 'en',
                name: 'English',
                flag: 'assets/images/flags/us.jpg',
            },
            {
                id: 'vi',
                name: 'Tiếng Việt',
                flag: 'assets/images/flags/vietnam.png',
            },
            // {
            //     id: 3,
            //     name: 'Italian',
            //     flag: 'assets/images/flags/italy.jpg',
            // },
            // {
            //     id: 4,
            //     name: 'Spanish',
            //     flag: 'assets/images/flags/spain.jpg',
            // },
            // {
            //     id: 5,
            //     name: 'Russian',
            //     flag: 'assets/images/flags/russia.jpg',
            // }
        ];

        this.selectedLanguage = this.languages.find(x => x.id === this.cookieService.getCookie('lang'));
        this.changeLanguage(this.selectedLanguage);
    }

    /**
     * Fetches the notification
     * Note: For now returns the hard coded notifications
     */
    _fetchNotifications() {
        this.notificationItems = [{
            text: 'Caleb Flakelar commented on Admin',
            subText: '1 min ago',
            icon: 'mdi mdi-comment-account-outline',
            bgColor: 'primary',
            redirectTo: '/notification/1'
        },
            {
                text: 'New user registered.',
                subText: '5 min ago',
                icon: 'mdi mdi-account-plus',
                bgColor: 'info',
                redirectTo: '/notification/2'
            },
            {
                text: 'Cristina Pride',
                subText: 'Hi, How are you? What about our next meeting',
                icon: 'mdi mdi-comment-account-outline',
                bgColor: 'success',
                redirectTo: '/notification/3'
            },
            {
                text: 'Caleb Flakelar commented on Admin',
                subText: '2 days ago',
                icon: 'mdi mdi-comment-account-outline',
                bgColor: 'danger',
                redirectTo: '/notification/4'
            },
            {
                text: 'Caleb Flakelar commented on Admin',
                subText: '1 min ago',
                icon: 'mdi mdi-comment-account-outline',
                bgColor: 'primary',
                redirectTo: '/notification/5'
            },
            {
                text: 'New user registered.',
                subText: '5 min ago',
                icon: 'mdi mdi-account-plus',
                bgColor: 'info',
                redirectTo: '/notification/6'
            },
            {
                text: 'Cristina Pride',
                subText: 'Hi, How are you? What about our next meeting',
                icon: 'mdi mdi-comment-account-outline',
                bgColor: 'success',
                redirectTo: '/notification/7'
            },
            {
                text: 'Caleb Flakelar commented on Admin',
                subText: '2 days ago',
                icon: 'mdi mdi-comment-account-outline',
                bgColor: 'danger',
                redirectTo: '/notification/8'
            }];
    }

    dropdownAccount(e: boolean) {
        this.dropdownOpen = e;
    }
}
