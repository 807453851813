import {Component, Input, OnInit} from '@angular/core';
import {CameraSettingService} from '../camera-setting/camera-setting.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-camera-share-management',
  templateUrl: './camera-share-management.component.html',
  styleUrls: ['./camera-share-management.component.scss']
})
export class CameraShareManagementComponent implements OnInit {
  @Input() camId = '';
  shareList: any = [];

  constructor(private cameraSettingService: CameraSettingService) { }

  ngOnInit() {
    this.fetchShareList();
  }

  fetchShareList() {
    this.cameraSettingService.getListCameraShare(this.camId).subscribe(data => {
      if (data) {
        this.shareList = data.shareList;
      }
    });
  }

  removeShare(item: any) {
    Swal.fire({
      title: 'Bạn muốn ngừng chia sẻ camera đến ' + '<span class="ml-1 text-primary">' + item.username + '</span>',
      // text: '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Đồng ý'
    }).then((result) => {
      if (result.value) {
        this.cameraSettingService.removeCameraShare(this.camId, item.userId).subscribe(data => {
          if (data) {
            Swal.fire('Hoàn thành!', 'Đã ngừng chia sẻ camera đến người dùng này.', 'success');
          }
        });
      }
    });
  }
}
