import {Injectable} from '@angular/core';
import Web3 from 'web3';
import {ethers} from 'ethers';
import {abi} from '../json/pancake-router-abi.json';
import {address} from '../../../environments/environment';

declare let window: any;


@Injectable({
    providedIn: 'root',
})
export class BlockchainService {
    constructor() {
        window.ethereum?.on('chainChanged', (accounts: any) => {
            window.location.reload(); // or your own code
        });
    }

    async loadWeb3() {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
            await window.ethereum.request({method: 'eth_requestAccounts'});
        } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider);
        } else {
            window.alert(
                'Non-Ethereum browser detected. You Should consider using MetaMask!'
            );
        }
    }

    async getWalletAddress() {
        const web3 = new Web3(Web3.givenProvider);
        console.log('web3', web3.eth);
        return (await web3.eth.getAccounts())[0];
    }

    async getETHBalance() {
        const web3 = new Web3(Web3.givenProvider);
        const walletAddress = await this.getWalletAddress();
        return web3.utils.fromWei(await web3.eth.getBalance(walletAddress));
    }

    async getTokenBalance(token: string) {
        const web3 = new Web3(Web3.givenProvider);
        const walletAddress = await this.getWalletAddress();
        const tokenAddress = address[token];
        console.log(tokenAddress);
        const contract = new web3.eth.Contract(
            [
                {
                    constant: true,
                    inputs: [
                        {
                            name: '_owner',
                            type: 'address',
                        },
                    ],
                    name: 'balanceOf',
                    outputs: [
                        {
                            name: 'balance',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
            ],
            tokenAddress
        );
        const result = await contract.methods
            .balanceOf(walletAddress)
            .call()
            .catch((err: any) => {
                return 'Unavailable';
            });
        return result.toString() == 'Unavailable'
            ? 'Unavailable'
            : web3.utils.fromWei(result.toString());
    }

    async getAmountSwapTokenForToken(
        amountIn: string,
        fromToken: string,
        toToken: string
    ) {
        var web3 = new Web3(Web3.givenProvider);
        var fromTokenAddress =
            fromToken == 'ACH'
                ? address.ACH
                : fromToken == 'BNB' || fromToken == 'WBNB'
                    ? address.WBNB
                    : '';

        var toTokenAddress =
            toToken == 'ACH'
                ? address.ACH
                : toToken == 'BNB' || toToken == 'WBNB'
                    ? address.WBNB
                    : '';

        var contract = new web3.eth.Contract(
            JSON.parse(JSON.stringify(abi)),
            address.PANCAKE_ROUTER
        );
        var res = await contract.methods
            .getAmountsOut(web3.utils.toBN(web3.utils.toWei(amountIn)), [
                fromTokenAddress,
                toTokenAddress,
            ])
            .call();
        return web3.utils.fromWei(res[1].toString());
    }

    async swapExactTokensForTokens(
        amountIn: string,
        fromToken: string,
        toToken: string
    ) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        const pancakeswap = new ethers.Contract(
            address.PANCAKE_ROUTER,
            JSON.parse(JSON.stringify(abi)),
            signer
        );

        var fromTokenAddress =
            fromToken == 'ACH'
                ? address.ACH
                : fromToken == 'BNB' || fromToken == 'WBNB'
                    ? address.WBNB
                    : '';

        var toTokenAddress =
            toToken == 'ACH'
                ? address.ACH
                : toToken == 'BNB' || toToken == 'WBNB'
                    ? address.WBNB
                    : '';

        if (fromTokenAddress == '' || toTokenAddress == '') {
            return;
        }
        const tx = await pancakeswap['swapExactTokensForTokens'](
            ethers.utils.parseEther(amountIn),
            0,
            [fromTokenAddress, toTokenAddress],
            await signer.getAddress(),
            Math.floor(Date.now() / 1000) + 60 * 20,
            {
                gasLimit: ethers.utils.hexlify(200000),
                gasPrice: ethers.utils.parseUnits('10', 'gwei'),
            }
        );
        const receipt = await tx.wait();
    }

    async swapExactTokensForETH(amountIn: string, fromToken: string) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        const pancakeswap = new ethers.Contract(
            address.PANCAKE_ROUTER,
            JSON.parse(JSON.stringify(abi)),
            signer
        );

        var fromTokenAddress =
            fromToken == 'ACH'
                ? address.ACH
                : fromToken == 'BNB' || fromToken == 'WBNB'
                    ? address.WBNB
                    : '';

        if (fromTokenAddress == '') {
            return;
        }

        const tx = await pancakeswap['swapExactTokensForETH'](
            ethers.utils.parseEther(amountIn),
            0,
            [fromTokenAddress, address.WBNB],
            await signer.getAddress(),
            Math.floor(Date.now() / 1000) + 60 * 20,
            {
                gasLimit: ethers.utils.hexlify(200000),
                gasPrice: ethers.utils.parseUnits('10', 'gwei'),
            }
        );
        const receipt = await tx.wait();
    }

    async swapExactETHForTokens(amountOut: string, toToken: string) {
        var web3 = new Web3(Web3.givenProvider);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        var walletAddress = await this.getWalletAddress();
        const signer = provider.getSigner();

        const pancakeswap = new ethers.Contract(
            address.PANCAKE_ROUTER,
            JSON.parse(JSON.stringify(abi)),
            signer
        );

        var toTokenAddress =
            toToken == 'ACH'
                ? address.ACH
                : toToken == 'BNB' || toToken == 'WBNB'
                    ? address.WBNB
                    : '';

        if (toTokenAddress == '') {
            return;
        }
        const tx = await pancakeswap['swapExactETHForTokens'](
            web3.utils.toWei(
                await this.getAmountSwapTokenForToken(amountOut, 'BNB', toToken)
            ),
            [address.WBNB, toTokenAddress],
            await signer.getAddress(),
            Math.floor(Date.now() / 1000) + 60 * 20,
            {
                gasLimit: ethers.utils.hexlify(200000),
                gasPrice: ethers.utils.parseUnits('10', 'gwei'),
                value: ethers.utils.parseEther(amountOut),
            }
        );
        const receipt = await tx.wait();
    }

    async transfer(value: any) {
        var web3 = new Web3(Web3.givenProvider);
        var ach_contract = address.ACH;
        var walletAddress = await this.getWalletAddress();

        var contract = new web3.eth.Contract(
            [
                {
                    constant: false,
                    inputs: [
                        {
                            name: '_to',
                            type: 'address',
                        },
                        {
                            name: '_value',
                            type: 'uint256',
                        },
                    ],
                    name: 'transfer',
                    outputs: [
                        {
                            name: '',
                            type: 'bool',
                        },
                    ],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [
                        {
                            name: '_owner',
                            type: 'address',
                        },
                    ],
                    name: 'balanceOf',
                    outputs: [
                        {
                            name: 'balance',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
            ],
            ach_contract
        );
        const amount = ethers.utils.parseUnits(value, 'ether');

        contract.methods
            .transfer(address.REMOTE_MONITORING_SERVER_WALLET_ADDRESS, amount)
            .send({from: walletAddress}, (err: any, res: any) => {
                if (err) {
                    console.log(err);
                    return;
                }
                console.log(res);
            });
    }

    async getNetwork(): Promise<string> {
        var web3 = new Web3(Web3.givenProvider);
        var netId = await web3.eth.net.getId();
        if (netId == 56) return 'Mainnet';
        else if (netId == 97) return 'Testnet';
        return 'Unknown';
    }
}
