import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../core/services/auth.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, AfterViewInit {
  changePasswordForm: UntypedFormGroup;
  submitted = false;
  error = '';
  loading = false;
  returnUrl: string;

  constructor(private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService) { }

  get f(): any {
    return this.changePasswordForm.controls;
  }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      oldPass: ['', [Validators.required]],
      newPass: ['', [Validators.required, Validators.minLength(6)]],
      confirmpwd: ['', [Validators.required]],
    }, {validators: this.checkPasswords});

    // get return url from route parameters or default to '/'
    // tslint:disable-next-line: no-string-literal
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  checkPasswords: ValidatorFn = (group: any): ValidationErrors | null => {
    const pass = group.get('newPass').value;
    const confirmPass = group.get('confirmpwd').value;
    return pass === confirmPass ? null : {notSame: true};
  }

  ngAfterViewInit() {
    document.body.classList.add('authentication-bg');
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {
      console.log('changePasswordForm', this.changePasswordForm);
      return;
    }

    this.loading = true;
    this.authenticationService.changePassword(this.f['oldPass'].value, this.f['newPass'].value).subscribe(data => {
          this.loading = false;
          this.success();
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  success = () => {
    Swal.fire({
      title: 'Thành công!',
      text: 'Dữ liệu đã được cập nhật.',
      type: 'success',
      showConfirmButton: false,
      // confirmButtonClass: 'btn btn-confirm mt-2'
    });
  }
}
