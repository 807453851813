import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {CameraSettingService} from '../camera-setting/camera-setting.service';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-alarm-setting',
    templateUrl: './alarm-setting.component.html',
    styleUrls: ['./alarm-setting.component.scss']
})
export class AlarmSettingComponent implements OnInit {
    @Input() camId = '';
    arrTime = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
        '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
    arrDay = ['days.Mon', 'days.Tue', 'days.Wed', 'days.Thu', 'days.Fri', 'days.Sar', 'days.Sun'];
    @Input() arrTimeChecked: any = [];
    @Input() daysOfWeekHours =
        {
            Mon: [],
            Tue: [],
            Wed: [],
            Thu: [],
            Fri: [],
            Sar: [],
            Sun: []
        };
    keyLang: any;
    arrKeyLang = [
        'notify.update_data_success', 'success'
    ];
    @Input() alarmStatus = false;
    @Input() alarmSoundStatus = false;
    @Input() popupNotifyStatus = false;

    constructor(private cameraSettingService: CameraSettingService,
                private translate: TranslateService) {
    }

    success = () => {
        Swal.fire({
            title: this.keyLang.success + '!',
            text: this.keyLang['notify.update_data_success'],
            type: 'success',
            showConfirmButton: false,
            // confirmButtonClass: 'btn btn-confirm mt-2'
        });
    };

    ngOnInit() {
        this.translate.stream(this.arrKeyLang).subscribe(data => {
            this.keyLang = data;
        });
        this.arrTime.forEach((x, i) => {
            this.arrTimeChecked.push([]);
            this.arrDay.forEach((y, z) => {
                this.arrTimeChecked[i].push(false);
            });
        });
    }

    checkTimeAlarm(e: any, xIndex: number, yIndex: number) {
        const day = this.arrDay[yIndex].substr(5, 3);
        const time = Number(this.arrTime[xIndex].substr(0, 2));

        if (e.target.checked) {
            if (this.daysOfWeekHours[day].find((x: number) => x === time) === undefined) {
                this.daysOfWeekHours[day].push(time);
                this.daysOfWeekHours[day].sort((a: number, b: number) => {
                    return a - b;
                });
            }
        } else {
            this.daysOfWeekHours[day].splice(this.daysOfWeekHours[day].indexOf(time), 1);
        }

        this.arrTimeChecked[xIndex][yIndex] = e.target.checked;
    }

    checkAllHour(e: any, index: number) {
        this.arrTimeChecked.forEach((x: any, i: number) => {
            this.checkTimeAlarm(e, i, index);
        });
    }

    checkAllDay(e: any, index: number) {
        this.arrTimeChecked[index].forEach((x: any, i: number) => {
            this.checkTimeAlarm(e, index, i);
        });
    }

    checkingAllHour(index: string | number) {
        const arr = this.arrTimeChecked.map((x: { [x: string]: any; }, i: any) => x[index]);
        return arr.every((x: any) => x);
    }

    checkingAllDay(index: string | number) {
        const arr = this.arrTimeChecked[index];
        return arr.every((x: any) => x);
    }

    saveAlarmHour() {
        this.cameraSettingService.setIntrusionAlarmHour(this.camId, this.daysOfWeekHours).subscribe((data) => {
            if (data) {
                this.success();
            }
        });
    }

    saveAlarmStatus(e: any) {
        this.alarmStatus = e.target.checked;
        this.cameraSettingService.setIntrusionAlarmStatus(this.camId, (this.alarmStatus ? 'on' : 'off')).subscribe((data) => {
            if (data) {
                this.success();
            }
        });
    }

    saveAlarmSound(e: any) {
        this.alarmSoundStatus = e.target.checked;
        this.cameraSettingService.setIntrusionAlarmSound(this.camId, (this.alarmSoundStatus ? 'on' : 'off')).subscribe((data) => {
            if (data) {
                this.success();
            }
        });
    }

    saveAlarmPopupNotify(e: any) {
        this.popupNotifyStatus = e.target.checked;
        this.cameraSettingService.setIntrusionAlarmPopupNotify(this.camId, (this.popupNotifyStatus ? 'on' : 'off')).subscribe((data) => {
            if (data) {
                this.success();
            }
        });
    }
}
