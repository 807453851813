import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {AngularFireMessaging} from "@angular/fire/compat/messaging";
@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  constructor(private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messages.subscribe((msg: any) => {
      msg.onMessage = msg.onMessage.bind(msg);
      msg.onTokenRefresh = msg.onTokenRefresh.bind(msg);
    });
  }

  // requestPermission() {
  //   this.angularFireMessaging.requestToken.subscribe(
  //       (token) => {
  //         console.log(token);
  //       },
  //       (err) => {
  //         console.error('Unable to get permission to notify.', err);
  //       }
  //   );
  // }

  requestPermission() {
    return this.angularFireMessaging.requestToken;
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      // console.log('new message received. ', payload);
      return this.currentMessage.next(payload);
    });
  }
}
