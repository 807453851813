import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PagesService } from '../../shared/service/pages.service';
import { Camera } from '../../shared/model/main';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../core/services/auth.service';
import { UserManagementService } from '../user-management/user-management.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { checkMobileMode } from '../../shared/utils/convert.util';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from "../../core/services/cookie.service";


declare let RTCPeerConnection: any;

@Component({
    selector: 'app-list-camera',
    templateUrl: './list-camera.component.html',
    styleUrls: ['./list-camera.component.scss']
})
export class ListCameraComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    @Input() refershListCamera = false;
    // bread crumb items
    breadCrumbItems: Array<any>;

    public base64tokenRes = 'Wait connect';

    turnServer = 'aivisvn.ddns.net:3478';

    listCamera: Array<Camera> = new Array<Camera>();

    pc: any;

    col = 2;

    videoLoadingImg = '/assets/images/camera/loading-gif.gif';


    genQRForm: UntypedFormGroup | any;
    shareCamForm: UntypedFormGroup | any;

    submitted = false;
    shareCamSubmitted = false;
    usernameHasUse = false;

    qrData: any = null;
    qrShow = false;
    // status arr cam
    isShakeHands: any = [];
    arrConnected: any = [];
    // Mic
    isMicShakeHands: any = [];
    arrMicConnected: any = [];
    mutedList: any = [];
    editListCam: any = [];
    loading: any = [];

    qrLoading = false;
    shareCamLoading = false;

    keyLang: any;
    arrKeyLang = [
        'notify.set_alarm_success', 'camera.list', 'success',
        'notify.update_camera_name_success', 'notify.update_ir_status_success',
        'notify.share_success'
    ];

    @ViewChild('checkUsernameDebounce', { static: true }) useranemInput: ElementRef;

    isMobileMode: boolean;

    constructor(
        private pageService: PagesService,
        private router: Router,
        private modalService: NgbModal,
        private formBuilder: UntypedFormBuilder,
        private authService: AuthenticationService,
        private cookieService: CookieService,
        private userManagementService: UserManagementService,
        private translate: TranslateService
    ) {
        this.isMobileMode = checkMobileMode();

        this.genQRForm = this.formBuilder.group({
            wifiName: ['', [Validators.required]],
            wifiPass: ['', [Validators.required]],
        });

        this.shareCamForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]],
            cameraId: [''],
        });


    }

    get f() {
        return this.genQRForm.controls;
    }

    get scf() {
        return this.shareCamForm.controls;
    }

    setAttributeVideo(id: string, attributeName: string, value: string) {
        // @ts-ignore
        document.getElementById(id).children[0].setAttribute(attributeName, value);
    }

    ngOnInit() {
        this.translate.stream(this.arrKeyLang).subscribe(data => {
            this.keyLang = data;
            this.breadCrumbItems = [{ label: 'Aivis', path: '/list-camera' }, {
                label: data['camera.list'],
                active: true
            }];
        });

        this.getListCamera();

        this.shareCamForm.get('username').valueChanges.pipe(
            debounceTime(500)
            , distinctUntilChanged())
            .subscribe((value: string) => {
                if (value) {
                    this.authService.checkUsername(value).subscribe(data => {
                        if (data) {
                            this.usernameHasUse = data.code === 1;
                        }
                    });
                }
            }, (error: any) => this.usernameHasUse = false);
    }

    ngOnChanges(changes: import('@angular/core').SimpleChanges) {
        if ((changes as any).refershListCamera.currentValue) {
            this.router.navigate(['/list-camera']);
            this.refreshCameraList();
        }
    }

    ngAfterViewInit() {
        // this.listCamera.forEach(x => {
        //     this.setAttributeVideo('cam' + x.cameraId, 'width', '100%');
        // });
    }

    ngOnDestroy() {
        // remoteDescription
        this.arrConnected.forEach((x: { obj: { close: () => any; }; }) => x.obj.close());
        this.arrMicConnected.forEach((x: { obj: { close: () => any; }; }) => x.obj.close());

    }

    refreshCameraList() {
        this.modalService.dismissAll();
        this.arrConnected.forEach((x: { obj: { close: () => any; }; }) => x.obj.close());
        this.arrMicConnected.forEach((x: { obj: { close: () => any; }; }) => x.obj.close());


        this.listCamera = [];
        this.isShakeHands = [];
        this.isMicShakeHands = [];
        this.arrConnected = [];
        this.arrMicConnected = [];
        this.mutedList = [];
        this.editListCam = [];
        this.loading = [];

        this.getListCamera();
    }

    getListCamera() {
        this.pageService.fetchCameraListWithSetting().subscribe((data) => {
            const res = data.cams || [];
            if (res && res.length > 0) {
                this.listCamera = res;
                this.pageService.setData(this.listCamera);

                this.listCamera.forEach(x => {
                    this.isShakeHands.push(false);
                    this.mutedList.push(false);
                    this.loading.push(false);
                    this.editListCam.push({
                        name: x.setting ? x.setting.name : '',
                        isEdit: false
                    });
                });
            }
        });
    }

    refreshCamera(cameraObj: Camera, index: number) {
        this.disconectCamera(cameraObj, index);
        this.shakeHands(cameraObj, index);
    }

    disconectCamera(cameraObj: Camera, i: number) {
        const elem: any = document.getElementById('cam' + cameraObj.cameraId);
        //Off Video
        this.arrConnected.find((x: { id: number; }) => x.id === i).obj.close();
        this.isShakeHands[i] = false;

        elem.removeChild(elem.childNodes[1]);
        elem.removeChild(elem.childNodes[1]);

        //Off Mic
        this.disconectMic(cameraObj, i);
    }

    disconectMic(cameraObj: Camera, i: number) {
        const elem: any = document.getElementById('cam' + cameraObj.cameraId);
        //Off Mic
        this.arrMicConnected.find((x: { id: number; }) => x.id === i)?.obj?.close();
        this.isMicShakeHands[i] = false;

        // elem.removeChild(elem.childNodes[1]);
        // elem.removeChild(elem.childNodes[1]);
    }

    onTheMic(cameraObj: Camera, index: number) {
        if (!this.isMicShakeHands[index]) {
            this.loading[index] = true;
            this.pageService.getTurnCredential().subscribe(data => {
                if (data) {
                    const pcConfig = {
                        iceServers: [
                            {
                                urls: 'stun:' + data.turnServer
                            },
                            {
                                urls: 'turn:' + data.turnServer,
                                username: data.username,
                                credential: data.password,
                            }
                        ]
                    };

                    const pc = new RTCPeerConnection(pcConfig);
                    const log = (msg: any) => {
                        // document.getElementById('log' + cameraObj.cameraId).innerHTML += msg + '<br>';
                    };

                    navigator.mediaDevices.getUserMedia({ video: false, audio: true })
                        .then(stream => {
                            stream.getTracks().forEach(track => pc.addTrack(track, stream))
                            pc.createOffer().then((d: any) => pc.setLocalDescription(d)).catch(log)
                        }).catch(log)

                    pc.oniceconnectionstatechange = (e: any) => log(pc.iceConnectionState);
                    pc.onicecandidate = (event: { candidate: null; }) => {
                        if (event.candidate === null) {
                            // console.log('base64 json', JSON.stringify(pc.localDescription));
                            const base64token = btoa(JSON.stringify(pc.localDescription));

                            this.pageService.requestAudioSignalWithTurn(cameraObj.cameraId, base64token, 'turn1dev.ddns.net:3478').subscribe(res => {
                                if (res.token) {
                                    this.base64tokenRes = res.token;
                                    // console.log('base64tokenRes', this.base64tokenRes);

                                    // start ontrack connect
                                    pc.setRemoteDescription(new RTCSessionDescription(JSON.parse(atob(res.token))));

                                    if (this.arrMicConnected.find((x: { id: number; }) => x.id === index)) {
                                        this.arrMicConnected.splice(this.arrMicConnected.indexOf(this.arrMicConnected.find((x: { id: number; }) => x.id === index)), 1);
                                    }
                                    this.arrMicConnected.push({ obj: pc, id: index });
                                    this.isMicShakeHands[index] = true;
                                    this.loading[index] = false;

                                }
                            }, error => {
                                this.loading[index] = false;
                            });
                        }

                    };

                    // Offer to receive 1 audio, and 1 video track
                    // pc.addTransceiver('video', {direction: 'sendrecv'});
                    // pc.addTransceiver('audio', {direction: 'sendrecv'});
                    // pc.createOffer().then((d: any) => pc.setLocalDescription(d)).catch(log);
                }
            }, error => {
                this.loading[index] = false;
            });
        } else {
            this.disconectMic(cameraObj, index);
            this.loading[index] = false;
        }
    }

    shakeHands(cameraObj: Camera, index: number) {
        this.loading[index] = true;
        this.pageService.getTurnCredential().subscribe(data => {
            if (data) {
                this.turnServer = data.turnServer;
                const pcConfig = {
                    iceServers: [
                        {
                            urls: 'stun:' + data.turnServer
                        },
                        {
                            urls: 'turn:' + data.turnServer,
                            username: data.username,
                            credential: data.password,
                        }
                    ]
                };

                const pc = new RTCPeerConnection(pcConfig);
                const log = (msg: any) => {
                    // document.getElementById('log' + cameraObj.cameraId).innerHTML += msg + '<br>';
                };

                pc.ontrack = (event: { track: { kind: string; }; streams: any[]; }) => {
                    const el: any = document.createElement(event.track.kind);
                    el.srcObject = event.streams[0];
                    el.autoplay = true;
                    el.controls = true;
                    el.muted = true;

                    if (event.track.kind === 'audio') {
                        el.controls = false;
                        el.muted = true;
                        // el.play();
                        this.mutedList[index] = false;
                    }

                    // @ts-ignore
                    document.getElementById('cam' + cameraObj.cameraId).appendChild(el);

                    setTimeout(() => {
                        this.setAttributeVideo('cam' + cameraObj.cameraId, 'width', '100%');
                        this.setAttributeVideo('cam' + cameraObj.cameraId, 'playsinline', 'true');

                        if (!this.isMobileMode) {
                            // @ts-ignore
                            document.getElementById('cam' + cameraObj.cameraId).children[0].controls = false;
                            // @ts-ignore
                            document.getElementById('cam' + cameraObj.cameraId).children[0].style.pointerEvents = 'none';
                        }

                    }, 300);
                };

                pc.oniceconnectionstatechange = (e: any) => log(pc.iceConnectionState);
                pc.onicecandidate = (event: { candidate: null; }) => {
                    if (event.candidate === null) {
                        // console.log('base64 json', JSON.stringify(pc.localDescription));
                        const base64token = btoa(JSON.stringify(pc.localDescription));

                        this.pageService.requestStreamSignalWithTurn(cameraObj.cameraId, base64token, this.turnServer).subscribe(res => {
                            if (res.token) {
                                this.base64tokenRes = res.token;
                                // console.log('base64tokenRes', this.base64tokenRes);

                                // start ontrack connect
                                pc.setRemoteDescription(new RTCSessionDescription(JSON.parse(atob(res.token))));

                                if (this.arrConnected.find((x: { id: number; }) => x.id === index)) {
                                    this.arrConnected.splice(this.arrConnected.indexOf(this.arrConnected.find((x: { id: number; }) => x.id === index)), 1);
                                }
                                this.arrConnected.push({ obj: pc, id: index });
                                this.isShakeHands[index] = true;
                                this.loading[index] = false;

                            }
                        }, error => {
                            this.loading[index] = false;
                        });
                    }

                };

                // Offer to receive 1 audio, and 1 video track
                pc.addTransceiver('video', { direction: 'sendrecv' });
                pc.addTransceiver('audio', { direction: 'sendrecv' });
                pc.createOffer().then((d: any) => pc.setLocalDescription(d)).catch(log);
            }
        }, error => {
            this.loading[index] = false;
        });
    }

    filterCol(col: number) {
        this.col = col;
    }

    viewHistory(cam: Camera, isShow: boolean) {
        this.router.navigate(['history-timeline', cam]);
    }

    setAlarmStatus(cam: Camera, i: number) {
        this.loading[i] = true;

        this.pageService.setAlaemStatus(cam.cameraId, cam.alarmStatus === 'on' ? 'off' : 'on').subscribe(data => {
            if (data) {
                this.loading[i] = false;
                this.success(this.keyLang['notify.set_alarm_success']);
                this.listCamera[i].alarmStatus = data.status;
            }
        }, error => this.loading[i] = false);
    }

    success = (intro: any) => {
        Swal.fire({
            title: this.keyLang.success + '!',
            text: intro,
            type: 'success',
            showConfirmButton: false,
            // confirmButtonClass: 'btn btn-confirm mt-2'
        });
    }

    openQRCode(qrCode: any) {
        this.modalService.open(qrCode, { centered: true });
    }

    openShareCamera(shareCamera: any, camId: string) {
        this.scf.cameraId.setValue(camId);
        this.modalService.open(shareCamera, { centered: true });
    }

    onSubmitGenQR() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.genQRForm.invalid) {
            return;
        }

        this.qrLoading = true;
        setTimeout(() => {
            const userId = this.authService.currentUser().userId;
            console.log('userId', userId)
            this.qrData = (this.f.wifiName.value + '()' + this.f.wifiPass.value + '()' + userId).toString();
            this.qrShow = true;
            this.qrLoading = false;
        }, 900);

    }

    backToAddCamera() {
        this.qrShow = false;
    }

    setVolumeVideo(cameraId: string, index: number) {
        // @ts-ignore
        const vid: any = document.getElementById('cam' + cameraId).children[0];
        // @ts-ignore
        const muted = vid.muted;
        // @ts-ignore
        vid.muted = !muted;

        this.mutedList[index] = muted;
    }

    editCameraName(i: number, status: boolean) {
        this.editListCam[i].isEdit = status;
        this.editListCam[i].name = this.listCamera[i].setting.name || '';
    }

    saveCameraName(i: number) {
        this.loading[i] = true;

        this.pageService.renameCamera(this.listCamera[i].cameraId, this.editListCam[i].name).subscribe(data => {
            if (data) {
                this.loading[i] = false;
                this.success(this.keyLang['notify.set_alarm_success']);
                this.editListCam[i].isEdit = false;
                this.listCamera[i].setting.name = this.editListCam[i].name || '';
            }
        }, error => {
            this.editListCam[i].isEdit = false;
            this.loading[i] = false;
        });
    }

    setStatusIR(status: string, i: number) {
        this.loading[i] = true;

        this.pageService.setIRStatus(this.listCamera[i].cameraId, status).subscribe(data => {
            if (data) {
                this.loading[i] = false;
                this.success(this.keyLang['notify.update_ir_status_success']);
                this.listCamera[i].ir_status = status;
            }
        }, error => {
            this.loading[i] = false;
        });
    }

    onShareCamera() {
        this.shareCamSubmitted = true;

        // stop here if form is invalid
        if (this.shareCamForm.invalid || this.usernameHasUse) {
            return;
        }

        this.shareCamLoading = true;
        this.pageService.shareCamera(this.shareCamForm.value).subscribe(data => {
            if (data) {
                this.scf.username.setValue('');
                this.shareCamLoading = false;
                this.success(this.keyLang['notify.share_success']);
                this.modalService.dismissAll();
            }
        }, error => this.shareCamLoading = false);
    }

    setDirection(item: Camera, direction: string) {
        this.pageService.setCamPTZ(item.cameraId, direction).subscribe();
    }
}
