<div class="container-fluid">

    <app-page-title [breadcrumbItems]="breadCrumbItems"
                    [title]="('config' | translate) + ' camera ' + camId"></app-page-title>

    <div class="row">
        <app-ui-preloader [display]="loading"></app-ui-preloader>
        <div class="col-md-3 col-sm-12">
            <ul #nav="ngbNav" [(activeId)]="active" class="nav-pills" ngbNav orientation="vertical">
                <li ngbNavItem="zoneMotion">
                    <a ngbNavLink>{{'setting.detect_move' | translate}}</a>
                    <ng-template *ngIf="cropperPosition" ngbNavContent>
                        <app-image-cropper (updateCopperImage)="getSetting()" [camId]="camId"
                                           [cropperInit]="cropperPosition"></app-image-cropper>
                    </ng-template>
                </li>
                <li *ngIf="roleByCamera === 'admin'" ngbNavItem="share">
                    <a ngbNavLink>{{'setting.share' | translate}}</a>
                    <ng-template ngbNavContent>
                        <app-camera-share-management [camId]="camId"></app-camera-share-management>
                    </ng-template>
                </li>
                <li ngbNavItem="alarm">
                    <a ngbNavLink>{{'setting.alarm' | translate}}</a>
                    <ng-template ngbNavContent>
                        <app-alarm-setting [alarmSoundStatus]="alarmSoundStatus" [alarmStatus]="alarmStatus"
                                           [arrTimeChecked]="arrTimeChecked" [camId]="camId"
                                           [daysOfWeekHours]="daysOfWeekHours"
                                           [popupNotifyStatus]="popupNotifyStatus"></app-alarm-setting>
                    </ng-template>
                </li>
                <li ngbNavItem="network">
                    <a ngbNavLink>{{'setting.network' | translate}}</a>
                    <ng-template ngbNavContent>
                        <app-network-management [camId]="camId"></app-network-management>
                    </ng-template>
                </li>

                <li ngbNavItem="updateFirmware">
                    <a ngbNavLink>{{'update_firmware' | translate}}</a>
                    <ng-template ngbNavContent>
                        <app-update-firmware [camId]="camId"></app-update-firmware>
                    </ng-template>
                </li>

<!--                <li ngbNavItem="placeOrder">-->
<!--                    <a ngbNavLink>placeOrder</a>-->
<!--                    <ng-template ngbNavContent>-->
<!--                        <app-place-order [camId]="camId"></app-place-order>-->
<!--                    </ng-template>-->
<!--                </li>-->

                <li ngbNavItem="developerOptions">
                    <a class="text-danger" ngbNavLink>{{'developer_options' | translate}}</a>
                    <ng-template ngbNavContent>
                        <div class="card-box">
                            <div class="row">
                                <div class="col-6">
                                    <button (click)="restart()" class="btn btn-warning w-100">Restart</button>
                                </div>
                                <div class="col-6">
                                    <button (click)="reboot()" class="btn btn-danger w-100">Reboot</button>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>

                <li class="mt-3">
                    <button (click)="deleteCamera()" class="btn btn-danger w-100">{{'camera.delete' | translate}}<i
                            class="ml-1 fas fa-video-slash"></i></button>
                </li>
            </ul>
        </div>

        <div class="col-md-9 col-sm-12">
            <div [ngbNavOutlet]="nav" class="ml-md-4 ml-0"></div>
        </div>
    </div>

</div>
