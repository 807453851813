import {AfterViewInit, Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
// @ts-ignore
import {first} from 'rxjs/operators';

import {AuthenticationService} from '../../../core/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EnvService} from '../../../../env.service';
import {CookieService} from '../../../core/services/cookie.service';
import {UserManagementService} from '../../../pages/user-management/user-management.service';
import {MessagingService} from '../../../messaging.service';
import {PagesService} from '../../../shared/service/pages.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

    loginForm: UntypedFormGroup;
    submitted = false;
    returnUrl: string;
    error = '';
    loading = false;
    rememberDay = 0;
    environmentStorage: any = '';

    firebaseToken: any = '';

    constructor(private formBuilder: UntypedFormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private envService: EnvService,
                private cookieService: CookieService,
                private authenticationService: AuthenticationService,
                private userManagementService: UserManagementService,
                private pagesService: PagesService,
                private messagingService: MessagingService,
    ) {
        this.messagingService.requestPermission().subscribe((data: any) => {
            this.firebaseToken = data;
        });
    }

    // convenience getter for easy access to form fields
    get f(): any {
        return this.loginForm.controls;
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', Validators.required],
        });

        // reset login status
        this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        // tslint:disable-next-line: no-string-literal
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

        setTimeout(() => {
            this.environmentStorage = this.envService.getEnvironment() || this.cookieService.getCookie('environment');
        }, 600);
    }

    ngAfterViewInit() {
        document.body.classList.add('authentication-bg');
    }

    /**
     * On submit form
     */
    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        const rememberDay = 1;
        this.authenticationService.login(this.f['username'].value, this.f['password'].value, rememberDay)
            .pipe(first())
            .subscribe(
                (res: any) => {
                    if (res) {
                        this.userManagementService.getPersonalInfo().subscribe(async (data: any) => {
                            if (data) {
                                // []devices<base64> chưa dùng tới, chiếm length cookie
                                data.devices = null;
                                const user = await {
                                    token: this.authenticationService.currentUser().token,
                                    refreshToken: this.authenticationService.currentUser().refreshToken,
                                    ...data
                                };

                                console.log('login user', user);

                                this.cookieService.setCookie('currentUser', JSON.stringify(user), rememberDay);
                                console.log('login', this.cookieService.getCookie('currentUser'));
                            }
                        });

                        this.pagesService.addDeviceToken(this.firebaseToken).subscribe();

                        this.router.navigate([this.returnUrl]);
                    }

                },
                (error: string) => {
                    this.error = error;
                    this.loading = false;
                });
    }

    rememberMe(e: any) {
        e.target.checked ? this.rememberDay = 30 : this.rememberDay = 0;
    }
}
