import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {CookieService} from '../../../core/services/cookie.service';
import {CameraRemoteService} from '../../../pages/camera-remote/camera-remote.service';
import {Camera} from '../../model/main';

@Component({
    selector: 'app-history-stream',
    templateUrl: './history-stream.component.html',
    styleUrls: ['./history-stream.component.scss']
})
export class HistoryStreamComponent implements OnInit, AfterViewInit {
    cam: any = null;

    constructor(
        private cookieService: CookieService,
        private cameraRemoteService: CameraRemoteService,
    ) {
        this.cam = JSON.parse(<string>this.cookieService.getCookie('HIS_STREAM')) || null;
    }

    ngOnInit() {


    }

    ngAfterViewInit() {
        console.log(this.cam);
        if (this.cam) {
            this.cameraRemoteService.fetchListCamHistory(this.cam.cameraId).subscribe(res => {
                console.log(res);
            });
        }
    }

}
