import {Component, Input, OnInit} from '@angular/core';
import {BlockchainService} from '../../core/services/blockchain.service';
import {ethers} from 'ethers';

@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.scss']
})
export class PlaceOrderComponent implements OnInit {
  @Input() camId = '';
  walletAddress: string;
  network: string;
  balances: any;

  constructor(private blockchainService: BlockchainService) {
    this.walletAddress = '';
    this.network = 'Unkown';
    this.balances = [];
  }

  async ngOnInit(): Promise<void> {
    this.walletAddress = await this.blockchainService.getWalletAddress();
    console.log(this.walletAddress);
    this.getNetwork();
  }

  async getNetwork() {
    this.network = await this.blockchainService.getNetwork();
    this.balances = [
      await this.blockchainService.getTokenBalance('ACH'),
      await this.blockchainService.getETHBalance(),
      await this.blockchainService.getTokenBalance('WBNB'),
    ];
    console.log(this.network);
    console.log(this.balances);
  }

  async connectWallet() {
    await this.blockchainService.loadWeb3();
    this.walletAddress = await this.blockchainService.getWalletAddress();
    console.log(this.walletAddress);
    this.getNetwork();
  }

  transfer() {
    this.blockchainService.transfer('10');
  }
}
