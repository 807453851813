import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'statusIrPipe'
})
export class StatusIrPipePipe implements PipeTransform {

    transform(status: string): any {
        switch (status) {
            case 'auto':
                return 'Tự động';
            case 'on':
                return 'Mở';
            case 'off':
                return 'Tắt';
            default:
                return 'Chưa đặt';
        }
    }

}
