<div class="container-fluid py-3">
    <div *ngIf="isMobileMode" class="toggle-sticky">
        <button (click)="toggleRightSidebar()"
                class="btn btn-sm btn-primary right-bar-toggle toggle-sticky-btn">
            <i class="fe-settings mr-1"></i> {{'move' | translate}}
        </button>
    </div>

    <ng-container *ngIf="isMobileMode">
        <div class="row justify-content-center mb-5">
            <ng-container [ngTemplateOutlet]="vjsPlayer"></ng-container>
            <ng-container [ngTemplateOutlet]="videosScroll"></ng-container>
        </div>
    </ng-container>

    <div class="card-box ribbon-box">
        <!--        <div (click)="toggleRightSidebar()" [ngClass]="{'shape': false}"-->
        <!--             class="d-block d-sm-none ribbon ribbon-primary float-right right-bar-toggle cursor-pointer">-->
        <!--            <i class="fe-settings mr-1"></i> Chuyển động-->
        <!--        </div>-->
        <div class="row justify-content-center ribbon-content px-2">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div class="form-group mb-3">
                    <label class="control-label text-primary">{{'day' | translate}}:
                        <em *ngIf="earliestDate" class="font-weight-bold text-warning">
                            ({{earliestDate}} {{'notify.is_earliestDate_view' | translate}})</em>
                    </label>
                    <div class="input-group clockpicker">
                        <input #d="ngbDatepicker" (click)="d.toggle()"
                               (dateSelect)="onDateSelection($event)" [(ngModel)]="ngDateChoose"
                               [maxDate]="ngbDateCurrent" class="form-control cursor-pointer"
                               ngbDatepicker
                               placeholder="yyyy-mm-dd"
                               readonly>
                        <div (click)="d.toggle()" class="input-group-append">
                            <span class="input-group-text cursor-pointer"><i
                                    class="mdi mdi-calendar"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-3">
                <div class="form-group">
                    <label class="control-label text-primary">{{'time' | translate}}:</label>
                    <input (ngModelChange)="onTimeSelection($event)" [(ngModel)]="time" class="form-control"
                           id="example-time" name="time"
                           type="time">
                </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                <div class="form-group">

                    <label class="control-label text-primary">{{'move' | translate}} {{secondWait > 0.1 ? ('notify.ready_in' | translate) + ' ' : ''}}
                        <span *ngIf="secondWait > 0.1" class="text-warning">{{secondWait | number}}s</span>
                    </label>

                    <div>
                        <button (debounceClick)="prepareDetectEvent()" *ngIf="!isMotionMode" appDebounceClick
                                class="btn btn-warning w-100"> {{'notify.start_filter_move' |  translate}}
                        </button>
                        <ng-select
                                (change)="getTimelineData(null, true)"
                                *ngIf="isMotionMode"
                                [(ngModel)]="actionType"
                                [clearable]="false"
                                [disabled]="secondWait > 0.1"
                                [items]="listAction"
                                bindLabel="name"
                                bindValue="value">
                        </ng-select>

                    </div>
                </div>

            </div>

            <div class="col-12 px-md-2 px-0 mb-3">
                <div #visualization [hidden]="isMobileMode" class="w-100"></div>
            </div>

            <div class="col-xl-2 col-lg-4 col-md-12 col-sm-12">
                <button (click)="getRecordVideo()" *ngIf="!isMobileMode" [disabled]="!enableVideo"
                        class="btn btn-primary w-100" type="button">
                    <span *ngIf="!enableVideo" aria-hidden="true" class="spinner-border spinner-border-sm mr-1"
                          role="status"></span>
                    Load video
                </button>
                <button (click)="setRecordVideo({time: time})" *ngIf="isMobileMode"
                        [disabled]="!enableVideo" class="btn btn-primary w-100" type="button">
                    <span *ngIf="!enableVideo" aria-hidden="true" class="spinner-border spinner-border-sm mr-1"
                          role="status"></span>Load video
                </button>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!isMobileMode">
        <div class="row justify-content-center">
            <ng-container [ngTemplateOutlet]="videosScroll"></ng-container>
            <ng-container [ngTemplateOutlet]="vjsPlayer"></ng-container>
        </div>
    </ng-container>

    <div class="row">
        <div class="col-12">
            <!--            <div class="zoom bg-primary" (click)="onZoom($event)">-->
            <!--                <h1>Zoom meeeee</h1>-->
            <!--            </div>-->
        </div>
    </div>
</div>


<!--<a (click)="toggleRightSidebar()" class="nav-link right-bar-toggle waves-effect waves-light"-->
<!--   href="javascript:void(0);">-->
<!--    <i class="fe-settings noti-icon">Mở</i>-->
<!--</a>-->

<app-rightsidebar (clickGetRecordVideo)="setRecordVideo($event)"
                  *ngIf="isMobileMode"
                  [listTimeData]="listTimeData" [mode]="'VIS_TIMELINE'"
                  [ngDateChoose]="ngDateChoose"></app-rightsidebar>


<ng-template #vjsPlayer>
    <div class="col-12 px-md-2 px-0">
        <div class="card">
            <div class="card-img-top overflow-hidden">
                <app-vjs-player (endedVideo)="nextTo($event)" *ngIf="enableVideo"
                                [options]="videoOnPlay">
                </app-vjs-player>

                <app-vjs-player *ngIf="!enableVideo"
                                [options]="videoNotFound">
                </app-vjs-player>

            </div>
        </div>
    </div>
</ng-template>

<ng-template #videosScroll>
    <div class="col-12 px-md-2 px-0">
        <app-ui-preloader [display]="isLoadingVideo"></app-ui-preloader>
        <div #scrollElement (wheel)="onWheel($event)" class="container-item" id="custom-scrollbar">
            <div (click)="loadMoreVideo('before')" *ngIf="listFileName.length > 0"
                 class="card video-item" id="video-item-before">
                <div class="card-img-top m-cursor-load w-100 bg-success">
                    <i class="fas fa-sync-alt fa-3x text-light item-center"></i>
                </div>

                <div class="card-body text-center">
                    <h5 class="card-title">{{'view.more' | translate}}</h5>
                </div>
            </div>

            <div (click)="goToVideo(item, i)" *ngFor="let item of listFileName; let i = index"
                 class="card video-item ml-1" id="video-item-{{i}}">
                <div class="card-img-top m-cursor w-100 {{index === i ? 'bg-primary' : 'bg-secondary'}}">
                    <i class="fas fa-play fa-3x text-light item-center"></i>
                </div>

                <div class="card-body text-center">
                    <h5 class="card-title">{{item | timePipe}}</h5>
                </div>
            </div>

            <div (click)="loadMoreVideo('after')" *ngIf="listFileName.length > 0"
                 class="card video-item ml-1" id="video-item-after">
                <div class="card-img-top m-cursor-load w-100 bg-success">
                    <i class="fas fa-sync-alt fa-3x text-light item-center"></i>
                </div>

                <div class="card-body text-center">
                    <h5 class="card-title">{{'view.more' | translate}}</h5>
                </div>
            </div>
        </div>
    </div>
</ng-template>
