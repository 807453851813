import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../core/services/auth.service';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, first, map} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {UserManagementService} from "../../../pages/user-management/user-management.service";
import {CookieService} from "../../../core/services/cookie.service";

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, AfterViewInit {

    signupForm: UntypedFormGroup;
    submitted = false;
    error = '';
    loading = false;
    returnUrl: string;
    usernameHasUse = false;

    @ViewChild('checkUsernameDebounce', {static: true}) useranemInput: ElementRef;

    constructor(private formBuilder: UntypedFormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private authenticationService: AuthenticationService,
                private modalService: NgbModal,
                private userManagementService: UserManagementService,
                private cookieService: CookieService,
    ) {
    }


    // convenience getter for easy access to form fields
    get f() : any {
        return this.signupForm.controls;
    }

    ngOnInit() {
        this.signupForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmpwd: ['', [Validators.required]],
        }, {validators: this.checkPasswords});

        // get return url from route parameters or default to '/'
        // tslint:disable-next-line: no-string-literal
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

        fromEvent(this.useranemInput.nativeElement, 'keyup').pipe(
            map((event: any) => {
                return event.target.value;
            })
            // , filter(res => res.length > 1)
            , debounceTime(1000)
            , distinctUntilChanged()
        ).subscribe((value: string) => {
            if (value) {
                this.authenticationService.checkUsername(value).subscribe(data => {
                    if (data) {
                        this.usernameHasUse = data.code === 1;
                    }
                });
            }
        });
    }

    checkPasswords: ValidatorFn = (group: any): ValidationErrors | null => {
        const pass = group.get('password').value;
        const confirmPass = group.get('confirmpwd').value;
        return pass === confirmPass ? null : {notSame: true};
    }

    ngAfterViewInit() {
        document.body.classList.add('authentication-bg');
    }

    /**
     * On submit form
     */
    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.signupForm.invalid || !this.usernameHasUse) {
            return;
        }

        this.loading = true;
        this.authenticationService.signup(this.f['username'].value, this.f['password'].value).subscribe(data => {
                if (data) {
                    this.authenticationService.logout();

                    this.authenticationService.login(this.f['username'].value, this.f['password'].value, 1)
                        .pipe(first())
                        .subscribe(
                            res => {
                                this.userManagementService.getPersonalInfo().subscribe(ur => {
                                    if (ur) {
                                        const user = {
                                            token: this.authenticationService.currentUser().token,
                                            ...ur
                                        };
                                        this.cookieService.setCookie('currentUser', JSON.stringify(user), 1);
                                    }
                                });

                                this.loading = false;
                                this.returnPage();
                            },
                            error => {
                                this.loading = false;
                            });
                    // this.router.navigate([this.returnUrl]);
                }
            },
            error => {
                this.error = error;
                this.loading = false;
            });

        // check email confirm
        // setTimeout(() => {
        //     this.loading = false;
        //     this.returnPage();
        //     // this.router.navigate(['/account/confirm']);
        // }, 1000);
    }

    returnPage() {
        Swal.fire({
            title: 'Thành công',
            text: 'Tài khoản đã được tạo',
            type: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#1abc9c',
            confirmButtonText: 'Đi đến trang chủ',
            cancelButtonText: 'Cập nhật bảo mật'
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/list-camera']);
            } else {
                this.router.navigate(['/user-management']);
            }
        });
    }
}
