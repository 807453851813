import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DataSet, Timeline} from 'vis-timeline';
import {VisTimelineService} from './vis-timeline.service';
import {ActivatedRoute} from '@angular/router';
import {Camera} from '../../shared/model/main';
import {CookieService} from '../../core/services/cookie.service';
import {CommandURL} from '../../shared/constant/ManageURLCommand';
import {NgbCalendar, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {EnvService} from '../../../env.service';
import {AuthenticationService} from '../../core/services/auth.service';
import {checkMobileMode} from '../../shared/utils/convert.util';


@Component({
    selector: 'app-vis-timeline',
    templateUrl: './vis-timeline.component.html',
    styleUrls: ['./vis-timeline.component.scss']
})
export class VisTimelineComponent implements OnInit, AfterViewInit {
    timeline: Timeline;
    options: {};
    data: any;
    groups: any;

    today = new Date();
    ngbDateCurrent = new NgbDate(this.today.getFullYear(), this.today.getMonth() + 1, this.today.getDate());
    date = this.today.getFullYear() + '/' + (this.today.getMonth() + 1) + '/' + this.today.getDate();
    timeOfDay = 86400000;

    @ViewChild('visualization', {static: true}) timelineContainer: ElementRef;
    @ViewChild('video', {static: true}) videoContainer: ElementRef;
    @ViewChild('scrollElement', {static: true}) scrollElement: ElementRef;

    cam: Camera;
    srcVideo: any;
    token: string | any;
    listFileName: Array<any> | any = [];
    enableVideo = true;
    enableTimeline = false;
    goInt: number | any;

    videoLoadingImg = '/assets/images/camera/loading-gif.gif';
    videoNotFoundImg = '/assets/images/camera/no-signal.gif';

    videoOnPlay: any;
    videoNotFound: any;
    index: any = 0;

    ngDateChoose: NgbDate;
    time = '00:00:00';
    timePoint = 0;
    listTimeData = [];
    listAction = [
        {
            name: 'Tất cả',
            value: 'all'
        },
        {
            name: 'Người',
            value: 'person'
        },
        {
            name: 'Con mèo',
            value: 'cat'
        },
        {
            name: 'Con chó',
            value: 'dog'
        },
        {
            name: 'Giường',
            value: 'bed'
        },
    ];
    actionType = 'all';
    secondWait = 0;
    isMobileMode: boolean;
    isMotionMode = false;

    // @HostListener('document:keydown', ['$event'])
    // handleKeyboardEvent(event: KeyboardEvent): void {
    //     event.preventDefault();
    //     if (event.key === 'F5') {
    //         // localStorage.setItem('secondWait', String(this.secondWait));
    //         this.cookieService.setCookie('secondWait', String(this.secondWait), 1 / 24);
    //         location.reload();
    earliestDate = '';
    isLoadingVideo = false;

    constructor(
        private visTimelineService: VisTimelineService,
        private route: ActivatedRoute,
        private authService: AuthenticationService,
        private cookieService: CookieService,
        private calendar: NgbCalendar,
        private envService: EnvService,
    ) {
        this.isMobileMode = checkMobileMode();

        setTimeout(() => {
            this.token = this.authService.currentUser().token;
        }, 300);

        this.route.paramMap.subscribe((obj: any) => {
            this.cam = obj.params;
            this.visTimelineService.getEarliestRecordDate(this.cam.cameraId).subscribe(data => {
                if (data) {
                    const d = data.dateString.substr(0, 10).split('-');
                    this.earliestDate = d[2] + '/' + d[1] + '/' + d[0];
                } else {
                    this.earliestDate = '';
                }
            });
        });

        this.data = new DataSet([
            // {
            //     content: '01:17:18',
            //     group: 1,
            //     id: 1640999838,
            //     start: '2022-01-01T01:17:18' // dễ sai lỗi string (2022-[001]-01T01:17:18)
            // }
        ]);


        if (this.isMobileMode) {
            const date = new Date(this.today.getTime() - 3600000);
            this.ngDateChoose = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
            this.time = date.toTimeString().substr(0, 8);
        } else {
            const date = new Date(this.today.getTime() - this.timeOfDay);
            this.ngDateChoose = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
        }

        this.getTimelineGroups();

        this.videoNotFound = {
            autoplay: false,
            controls: true,
            // sources: [{
            //     src: 'http://media.w3.org/2010/05/video/movie_300.mp4',
            //     type: 'video/mp4'
            // }],
            poster: this.videoNotFoundImg
        };

        // get coutDown don't end;
        const secondWait = Number(this.cookieService.getCookie('secondWait')) || 0;
        const timeOff = (this.today.getTime() - Number(this.cookieService.getCookie('timeOff'))) || 100;
        this.secondWait = secondWait - timeOff / 1000;

    }

    parseUTCDate(d: Date) {
        return new Date(d.getFullYear(), d.getMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds());
    }

    onWheel(event: WheelEvent) {
        event.preventDefault();
        this.scrollElement.nativeElement.scrollLeft += event.deltaY;
    }

    getTimelineGroups() {
        // create groups
        this.groups = new DataSet([
            {id: 1, content: ''},
            // {id: 2, content: 'Truck&nbsp;2'},
            // {id: 3, content: 'Truck&nbsp;3'},
            // {id: 4, content: 'Truck&nbsp;4'}
        ]);
    }

    secondWaitReduce() {
        clearInterval(this.goInt);
        this.goInt = setInterval(() => {
            if (this.secondWait < 0.1) {
                clearInterval(this.goInt);
            }
            this.secondWait -= 0.1;
            this.cookieService.setCookie('secondWait', String(this.secondWait), 1 / 24 / 12);
            this.cookieService.setCookie('timeOff', String(new Date().getTime()), 1 / 24 / 12);
        }, 100);

        // this.countDown = timer(0, 100).pipe(
        //     take(this.secondWait),
        //     map(() => --this.secondWait)
        // );
    }

    prepareDetectEvent() {
        const dateTimeChoose = new Date(this.ngDateChoose.year + '/' + this.ngDateChoose.month + '/' +
            this.ngDateChoose.day + ' ' + this.time).getTime();

        if (this.secondWait < 0.1) {
            this.visTimelineService.prepareDetectEventTimeout(this.cam.cameraId, dateTimeChoose).subscribe(data => {
                if (data) {
                    this.isMotionMode = true;
                    this.secondWait = data.secondWait;
                    this.secondWaitReduce();
                }
            }, error => this.isMotionMode = false);
        } else {
            this.secondWaitReduce();
        }
    }

    getTimelineData(dateTimeChoose: any, moveTo: boolean) {
        dateTimeChoose = dateTimeChoose || new Date(this.ngDateChoose.year + '/' + this.ngDateChoose.month + '/' +
            this.ngDateChoose.day + ' ' + this.time).getTime();

        this.data.clear();
        this.listTimeData = [];
        if (this.actionType === 'all') {
            this.visTimelineService.getEventList(this.cam.cameraId, dateTimeChoose).subscribe(data => {
                if (data && data.motion) {
                    this.listTimeData = data.motion.map((x: number, i: any) => {
                        const date = this.parseUTCDate(new Date(x * 1000));
                        let month = Number(date.getMonth());
                        return {
                            id: x,
                            group: 1,
                            content: date.toTimeString().substr(0, 8),
                            start: date.getFullYear() + '-' +
                                (month++ < 10 ? '0' + month : month) + '-' +
                                (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + 'T' +
                                date.toTimeString().substr(0, 8)
                        };
                    });

                    if (!this.isMobileMode) {
                        this.listTimeData.forEach(x => this.data.add(x));
                        if (moveTo) {
                            this.moveOnHaveMotion(dateTimeChoose);
                        }
                    } else {
                        this.toggleRightSidebar();
                    }
                }
            });
        } else {
            this.visTimelineService.getEventListFilter(this.cam.cameraId, dateTimeChoose, this.actionType).subscribe(data => {
                if (data && data[this.actionType]) {
                    this.listTimeData = data[this.actionType].map((x: number, i: any) => {
                        const date = this.parseUTCDate(new Date(x * 1000));
                        return {
                            id: x,
                            group: 1,
                            content: date.toTimeString().substr(0, 8),
                            start: date.getFullYear() + '-' +
                                (date.getMonth() + 1 < 10 ? '0' + date.getMonth() + 1 : date.getMonth() + 1) + '-' +
                                (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + 'T' +
                                date.toTimeString().substr(0, 8)
                        };
                    });

                    if (!this.isMobileMode) {
                        this.listTimeData.forEach(x => this.data.add(x));
                        if (moveTo) {
                            this.moveOnHaveMotion(dateTimeChoose);
                        }
                    } else {
                        this.toggleRightSidebar();
                    }
                }
            });
        }
    }

    moveOnHaveMotion(dateTimeChoose: string | number | Date) {
        // @ts-ignore
        const time = this.listTimeData[0].content.split(':');
        const date = new Date(dateTimeChoose);
        // console.log(this.listTimeData[0].content, time);
        // console.log(new Date(date.getFullYear(), date.getMonth(), date.getDate(), time[0], time[1], 0));
        this.timeline.moveTo(new Date(date.getFullYear(), date.getMonth(), date.getDate(), time[0], time[1], 0).getTime());
    }

    getOptions(dateTimeChoose: number) {
        this.options = {
            editable: false,
            showCurrentTime: false,

            // min: dateTimeChoose - (this.timeOfDay / 4),
            // max: dateTimeChoose + (this.timeOfDay * 1.5),
            // start: dateTimeChoose - (this.timeOfDay / 2),
            start: dateTimeChoose,
            end: dateTimeChoose + this.timeOfDay,
            format: {
                minorLabels: {
                    hour: 'h:mm A',
                }
            },

            zoomMax: 3600000,
            // zoomMin: 10,
            moveable: true,
            zoomable: true,
            horizontalScroll: true,
            verticalScroll: true
        };
    }

    // setAttributeVideo(id: string, attributeName: string, value: string) {
    //     document.getElementById(id).children[0].setAttribute(attributeName, value);
    // }

    ngOnInit() {
        this.onDateSelection(this.ngDateChoose);

        if (!this.isMobileMode) {
            this.loadVisTimeline();
            this.getTimelineData(new Date(this.ngDateChoose.year, this.ngDateChoose.month - 1,
                this.ngDateChoose.day, 12, 0, 0).getTime(), true);
        } else {
            this.setRecordVideo({time: this.time});
        }

        this.secondWaitReduce();
    }


    ngAfterViewInit() {
        // this.toggleRightSidebar();
    }

    loadVisTimeline() {

        // @ts-ignore
        this.timeline = new Timeline(this.timelineContainer.nativeElement, null, this.options);
        this.timeline.setGroups(this.groups);
        this.timeline.setItems(this.data);
        this.timeline.addCustomTime(new Date(), 'time-point');
        this.visTimelineService.setTimeline(this.timeline);

        this.onEventAction();
    }

    onEventAction() {
        this.timeline.on('click', (properties) => {
            if (properties.item) {
                this.timePoint = this.parseUTCDate(new Date(properties.item * 1000)).getTime();
                this.time = this.parseUTCDate(new Date(properties.item * 1000)).toTimeString().substr(0, 8);
                // this.debounceCallService(properties.item * 1000);

                this.getRecordVideo();
            }
        });

        this.timeline.on('rangechange', (properties) => {
            const timePoint = (properties.end.getTime() + properties.start.getTime()) / 2;
            const date = new Date(timePoint);
            const dateTime = new Date(timePoint).toTimeString().substr(0, 8);

            this.visTimelineService.getTimeline().setCustomTime(date, 'time-point');
            this.time = dateTime;
            // this.ngDateChoose = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
        });

        this.timeline.on('rangechanged', (properties) => {
            const timePoint = (properties.end.getTime() + properties.start.getTime()) / 2;
            const date = new Date(timePoint);
            const dateTime = new Date(timePoint).toTimeString().substr(0, 8);

            this.visTimelineService.getTimeline().setCustomTime(date, 'time-point');
            this.time = dateTime;

            if (date.getDate() !== this.ngDateChoose.day || (date.getMonth() + 1) !== this.ngDateChoose.month) {
                this.actionType = 'all';
                this.getTimelineData(date.getTime(), false);
            }

            this.ngDateChoose = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());

            // only first load
            if (this.timePoint === 0) {
                this.timePoint = timePoint;
                // this.getRecordVideo();
            }

            this.timePoint = timePoint;

        });
    }

    // input
    onDateSelection(date: NgbDate) {
        this.actionType = 'all';
        const dateTimeChoose = new Date(date.year + '/' + date.month + '/' + date.day + ' 00:00:00').getTime();
        this.getOptions(dateTimeChoose);

        if (this.timeline) {
            this.timeline.moveTo(dateTimeChoose);
            this.getTimelineData(dateTimeChoose, true);
        }

        if (this.isMobileMode) {
            this.getTimelineData(dateTimeChoose, false);
        }

    }

    onTimeSelection(time: any) {
        const date = new Date(this.ngDateChoose.year + '/' + this.ngDateChoose.month + '/' + this.ngDateChoose.day + ' ' + time);
        if (!(this.isMobileMode)) {
            this.timeline.moveTo(date);
        }
    }

    debounceCallService() {
        clearInterval(this.goInt);
        let i = 1;

        this.goInt = setInterval(() => {
            if (i > 10) {
                this.getRecordVideo();
                clearInterval(this.goInt);
            }
            i++;
        }, 100);

    }

    getRecordVideo() {
        this.listFileName = [];
        this.enableVideo = false;
        this.visTimelineService.getListVideo(this.cam.cameraId, this.timePoint).subscribe(res => {

            if (res && res.current) {
                const begin = res.current[0];
                const before = res.before.filter((x: any) => x);
                const current = res.current.filter((x: any) => x);
                const after = res.after.filter((x: any) => x);

                this.listFileName = [].concat(before.reverse(), current, after);
                this.setVideoOnPlay(this.cam.cameraId, begin);
                this.index = this.listFileName.indexOf(begin);

                // Aniamtion when load video
                this.scrollToVideoCurrent('video-item-' + this.listFileName.indexOf(begin));
            } else {
                this.enableVideo = true;
            }

        }, error => this.enableVideo = true);

        // this.enableVideo = false;
        // this.videoOnPlay = this.videoList[0];
        // setTimeout(() => {
        //     this.enableVideo = true;
        // }, 100);
    }

    nextTo(e: any) {
        this.enableVideo = false;
        // this.videoOnPlay = this.videoList[0];
        setTimeout(() => {
            // liên tục
            this.index === this.listFileName.length - 1 ? this.index = 0 : this.index += 1;
            this.setVideoOnPlay(this.cam.cameraId, this.listFileName[this.index]);
            this.scrollToVideoCurrent('video-item-' + this.index);
        }, 100);

    }

    goToVideo(nameVideo: any, index: string | number) {
        this.enableVideo = false;
        this.index = index;
        this.setVideoOnPlay(this.cam.cameraId, nameVideo);
        setTimeout(() => {
            this.scrollToVideoCurrent('video-item-' + index);
        }, 100);
    }

    setVideoOnPlay(camId: string, filename: string) {
        const apiUrl = this.envService.getEnvironment() || this.cookieService.getCookie('environment');
        const url = 'https://' + apiUrl + '/' + CommandURL.GET_RECORD_VIDEO_URL
            + '?cameraId=' + camId
            + '&filename=' + filename
            + '&token=' + this.token;

        this.videoOnPlay = {
            autoplay: true,
            controls: true,
            sources: [{
                src: url,
                // src: 'http://vjs.zencdn.net/v/oceans.mp4',
                type: 'video/mp4'
            }],
            poster: this.videoLoadingImg
        };
    }

    scrollToVideoCurrent(elmId: any) {
        setTimeout(() => {
            // @ts-ignore
            document.getElementById(elmId).scrollIntoView({
                behavior: 'smooth',
                block: (this.isMobileMode ? "nearest" : 'start'),
                inline: 'center'
            });
            this.enableVideo = true;
        }, 400);
    }

    public toggleRightSidebar() {
        document.body.classList.toggle('right-bar-enabled');
    }

    setRecordVideo(e: any) {
        const date = new Date(this.ngDateChoose.year + '/' + this.ngDateChoose.month + '/' + this.ngDateChoose.day + ' ' + e.time);
        this.timePoint = date.getTime();
        this.time = date.toTimeString().substr(0, 8);
        this.getRecordVideo();
    }

    loadMoreVideo(type: string) {
        this.isLoadingVideo = true;
        const fileName = type === 'before' ? this.listFileName[0] : this.listFileName[this.listFileName.length - 1];

        this.visTimelineService.loadModeVideoList(this.cam.cameraId, fileName, type).subscribe((data: any) => {
            this.isLoadingVideo = false;
            if (Array.isArray(data)) {
                const listFileNameAfterChange = this.listFileName;
                if (type === 'before') {
                    // @ts-ignore
                    this.listFileName = [].concat(data.reverse(), this.listFileName);
                } else {
                    // @ts-ignore
                    this.listFileName = [].concat(this.listFileName, data);
                }
                this.index = this.listFileName.indexOf(listFileNameAfterChange[this.index]);
            }
        }, error => this.isLoadingVideo = false);
    }
}
