<app-page-title [breadcrumbItems]="breadCrumbItems" [title]="'camera.list' | translate"></app-page-title>
<!-- content-->
<div class="row">
    <div class="col-12">
        <button (click)="openQRCode(qrCode)"
                class="btn btn-primary btn-sm mb-3">
            {{'camera.add' | translate}}<i class="ml-1 fas fa-plus"></i>
        </button>
        <div class="float-right d-none d-md-inline-block">
            <div class="btn-group mb-2">
                <button (click)="filterCol(1)" class="btn btn-xs btn-{{col === 1 ? 'primary' : 'secondary'}}"
                        type="button"><i class="fas fa-square"></i>
                </button>
                <button (click)="filterCol(2)" class="btn btn-xs btn-{{col === 2 ? 'primary' : 'secondary'}}"
                        type="button"><i class="fas fa-th-large"></i>
                </button>
                <button (click)="filterCol(3)"
                        class="d-md-none d-lg-block btn btn-xs btn-{{col === 3 ? 'primary' : 'secondary'}}"
                        type="button"><i class="fas fa-th"></i>
                </button>
            </div>
        </div>
    </div>

    <div *ngFor="let item of listCamera; let i = index;"
         class="col-lg-{{12/col}} col-md-{{12/(col === 3 ? 2 : col)}}  col-sm-12 px-md-2 px-0">

        <div class="card">
            <app-ui-preloader [display]="loading[i]"></app-ui-preloader>

            <div [id]="'cam' + item.cameraId" class="card-img-top">
                <div *ngIf="!isShakeHands[i]" class="video-container" id="video-container">
                    <video controls preload="metadata" width="100%"></video>

                    <div class="play-button-wrapper">
                        <div (click)="shakeHands(item, i)" class="play-gif circlePlayBtn" title="Play video">
                            <!-- SVG Play Button -->
                            <svg viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
                                <path d="M40 0a40 40 0 1040 40A40 40 0 0040 0zM26 61.56V18.44L64 40z"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <!--      pc      -->
            <div class="card-body d-lg-block d-none">
                <div class="row">
                    <div *ngIf="!editListCam[i].isEdit || true" class="col-xl-12">
                        <div class="input-group">
                            <h5 class="card-title">{{(item.setting ? item.setting.name : ('notify.noname' | translate)) | titlecase}}</h5>
                            <i (click)="editCameraName(i, true)" class="ml-1 fas fa-edit text-primary m-cursor"></i>
                            <em *ngIf="item.model && (item.model.search('P-') > 0)" class="text-primary font-weight-bold ml-2">360</em>
                        </div>
                    </div>
                    <div *ngIf="editListCam[i].isEdit || false" class="col-xl-12 mb-3">
                        <div class="input-group">
                            <input [(ngModel)]="editListCam[i].name" aria-describedby="basic-addon2"
                                   class="form-control" placeholder="{{'camera.enter_name' | translate}}"
                                   type="text">
                            <div class="input-group-append">
                                <button (click)="saveCameraName(i)" class="btn btn-primary waves-effect waves-light"
                                        type="button">{{'save' | translate}}
                                </button>
                                <button (click)="editCameraName(i, false)" class="btn btn-dark waves-effect waves-light"
                                        type="button">{{'cancel' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>

                    <p [id]="'log' + item.cameraId" class="card-text"></p>
                </div>

                <div *ngIf="item.model && (item.model.search('P-') > 0) && isShakeHands[i]" class="row mb-3 justify-content-center">
                    <div class="col-5">
                        <div class="row remote-control">
                            <div class="col-4"></div>
                            <div class="col-4">
                                <button (debounceClick)="setDirection(item, 'up')"
                                        appDebounceClick
                                        class="btn btn-sm btn-secondary w-100"><i class="fas fa-arrow-alt-circle-up"></i>
                                </button>
                            </div>
                            <div class="col-4"></div>

                            <div class="col-4">
                                <button (debounceClick)="setDirection(item, 'left')"
                                        appDebounceClick
                                        class="btn btn-sm btn-secondary w-100"><i
                                        class="fas fa-arrow-alt-circle-left"></i>
                                </button>
                            </div>
                            <div class="col-4">
                                <button disabled class="btn btn-sm btn-secondary w-100">
                                    <em>Aivis</em>
                                </button>
                            </div>
                            <div class="col-4">
                                <button (debounceClick)="setDirection(item, 'right')"
                                        appDebounceClick
                                        class="btn btn-sm btn-secondary w-100"><i
                                        class="fas fa-arrow-alt-circle-right"></i>
                                </button>
                            </div>

                            <div class="col-4"></div>
                            <div class="col-4">
                                <button (debounceClick)="setDirection(item, 'down')"
                                        appDebounceClick
                                        class="btn btn-sm btn-secondary w-100"><i
                                        class="fas fa-arrow-alt-circle-down"></i>
                                </button>
                            </div>
                            <div class="col-4"></div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-4 col-lg-6 mb-1">
                        <button (debounceClick)="setVolumeVideo(item.cameraId, i)" [disabled]="!isShakeHands[i]"
                                appDebounceClick
                                class="btn btn-sm btn-{{mutedList[i] ? 'primary' : 'secondary'}} w-100">
                            {{'volume' | translate}}:<i
                                class="ml-1 fas fa-{{mutedList[i] ? 'volume-up' : 'volume-mute'}}"></i>
                        </button>
                    </div>

                    <div class="col-xl-4 col-lg-6 mb-1">
                        <button (debounceClick)="setAlarmStatus(item, i)" appDebounceClick
                                class="btn btn-sm btn-{{item.alarmStatus === 'on' ? 'danger' : 'secondary'}} w-100">
                            {{'alarm' | translate}}:<i
                                class="ml-1 fas fa-{{item.alarmStatus === 'on' ? 'bell' : 'bell-slash'}}"></i>
                        </button>
                    </div>

                    <div class="col-xl-4 col-lg-6 mb-1">
                        <button (debounceClick)="onTheMic(item, i)" appDebounceClick
                                class="btn btn-sm {{!isMicShakeHands[i] ? ' btn-secondary ' : ' btn-primary '}} w-100">
                            {{'Micro: ' + (isMicShakeHands[i] ? 'On' : 'Off')}}
                            <i class="ml-1 fas {{!isMicShakeHands[i] ? ' fa-microphone-slash ' : ' fa-microphone '}}"></i>
                        </button>
                    </div>

                    <div class="col-xl-4 col-lg-6 mb-1">
                        <button (click)="openShareCamera(shareCamera, item.cameraId)"
                                class="btn btn-sm btn-primary w-100">
                            {{'share' | translate}} <i class="ml-1 fas fa-external-link-alt"></i>
                        </button>
                    </div>

                    <div class="col-xl-4 col-lg-6 mb-1">
                        <div class="dropdown d-inline" ngbDropdown>
                            <button [id]="'ir' + i" class="btn btn-sm btn-success dropdown-toggle waves-effect w-100"
                                    ngbDropdownToggle
                                    type="button"> IR: {{item.ir_status | translate}}
                                <i class="mdi mdi-chevron-down float-right"></i></button>
                            <div aria-labelledby="ir{i}" class="dropdown-menu" ngbDropdownMenu>
                                <a (click)="setStatusIR('auto', i)" class="dropdown-item"
                                   href="javascript: void(0);">{{'auto' | translate}}</a>
                                <a (click)="setStatusIR('on', i)" class="dropdown-item"
                                   href="javascript: void(0);">{{'on' | translate}}</a>
                                <a (click)="setStatusIR('off', i)" class="dropdown-item"
                                   href="javascript: void(0);">{{'off' | translate}}</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-6 mb-1">
                        <button [routerLink]="'/camera-setting/' + item.cameraId" class="btn btn-sm btn-success w-100">
                            {{'config' | translate}}<i class="ml-1 fas fa-cogs"></i>
                        </button>
                    </div>

                    <div class="col-xl-4 col-lg-6 mb-1">
                        <button (click)="viewHistory(item, true)"
                                class="btn btn-sm btn-primary w-100">
                            {{'view.history' | translate}} <i class="ml-1 fas fa-history"></i>
                        </button>
                    </div>


                    <div class="col-xl-4 col-lg-6">
                        <button (debounceClick)="refreshCamera(item, i)" *ngIf="isShakeHands[i]" appDebounceClick
                                class="btn btn-sm btn-warning w-100">
                            {{'refresh' | translate}}<i class="ml-1 fas fa-sync-alt"></i>
                        </button>
                    </div>

                    <div class="col-xl-4 col-lg-6">
                        <button (debounceClick)="disconectCamera(item, i)" *ngIf="isShakeHands[i]" appDebounceClick
                                class="btn btn-sm btn-danger w-100">
                            {{'turn_off' | translate}}<i class="ml-1 fas fa-power-off"></i>
                        </button>
                    </div>
                </div>
            </div>

            <!--      mobile      -->
            <div class="card-body d-block d-lg-none">
                <div class="row">
                    <div *ngIf="!editListCam[i].isEdit" class="col-12">
                        <div class="input-group">
                            <h5 class="card-title">{{(item.setting ? item.setting.name : 'Chưa đặt tên') | titlecase}}</h5>
                            <i (click)="editCameraName(i, true)" class="ml-1 fas fa-edit text-primary m-cursor"></i>
                            <em *ngIf="item.model && (item.model.search('P-') > 0)" class="text-primary font-weight-bold ml-2">360</em>
                        </div>
                    </div>
                    <div *ngIf="editListCam[i].isEdit" class="col-12 mb-3">
                        <div class="input-group">
                            <input [(ngModel)]="editListCam[i].name" aria-describedby="basic-addon2"
                                   class="form-control" placeholder="{{'camera.enter_name' | translate}}"
                                   type="text">
                            <div class="input-group-append">
                                <button (click)="saveCameraName(i)" class="btn btn-primary waves-effect waves-light"
                                        type="button">{{'save' | translate}}
                                </button>
                                <button (click)="editCameraName(i, false)" class="btn btn-dark waves-effect waves-light"
                                        type="button">{{'cancel' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>

                    <p [id]="'log' + item.cameraId" class="card-text"></p>
                </div>

                <div *ngIf="item.model && (item.model.search('P-') > 0) && isShakeHands[i]" class="row mb-3 justify-content-center">
                    <div class="col-7">
                        <div class="row remote-control">
                            <div class="col-4"></div>
                            <div class="col-4">
                                <button (debounceClick)="setDirection(item, 'up')"
                                        appDebounceClick
                                        class="btn btn-sm btn-secondary w-100"><i class="fas fa-arrow-alt-circle-up"></i>
                                </button>
                            </div>
                            <div class="col-4"></div>

                            <div class="col-4">
                                <button (debounceClick)="setDirection(item, 'left')"
                                        appDebounceClick
                                        class="btn btn-sm btn-secondary w-100"><i
                                        class="fas fa-arrow-alt-circle-left"></i>
                                </button>
                            </div>
                            <div class="col-4">
                                <button disabled class="btn btn-sm btn-secondary w-100">
                                    <em>Aivis</em>
                                </button>
                            </div>
                            <div class="col-4">
                                <button (debounceClick)="setDirection(item, 'right')"
                                        appDebounceClick
                                        class="btn btn-sm btn-secondary w-100"><i
                                        class="fas fa-arrow-alt-circle-right"></i>
                                </button>
                            </div>

                            <div class="col-4"></div>
                            <div class="col-4">
                                <button (debounceClick)="setDirection(item, 'down')"
                                        appDebounceClick
                                        class="btn btn-sm btn-secondary w-100"><i
                                        class="fas fa-arrow-alt-circle-down"></i>
                                </button>
                            </div>
                            <div class="col-4"></div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6 mb-1">
                        <button (debounceClick)="setAlarmStatus(item, i)"
                                appDebounceClick
                                class="btn btn-sm btn-{{item.alarmStatus === 'on' ? 'danger' : 'secondary'}} w-100">
                            {{'alarm' | translate}}:<i
                                class="ml-1 fas fa-{{item.alarmStatus === 'on' ? 'bell' : 'bell-slash'}}"></i>
                        </button>
                    </div>

                    <div class="col-6 mb-1">
                        <button (debounceClick)="onTheMic(item, i)" appDebounceClick
                                class="btn btn-sm {{!isMicShakeHands[i] ? ' btn-secondary ' : ' btn-primary '}} w-100">
                            {{'Micro: ' + (isMicShakeHands[i] ? 'On' : 'Off')}}
                            <i class="ml-1 fas {{!isMicShakeHands[i] ? ' fa-microphone-slash ' : ' fa-microphone '}}"></i>
                        </button>
                    </div>

                    <div class="col-6 mb-1">
                        <button (debounceClick)="setVolumeVideo(item.cameraId, i)" [disabled]="!isShakeHands[i]"
                                appDebounceClick
                                class="btn btn-sm btn-{{mutedList[i] ? 'primary' : 'secondary'}} w-100">
                            {{'volume' | translate}}:<i
                                class="ml-1 fas fa-{{mutedList[i] ? 'volume-up' : 'volume-mute'}}"></i>
                        </button>
                    </div>

                    <div class="col-6 mb-1">
                        <button (click)="openShareCamera(shareCamera, item.cameraId)"
                                class="btn btn-sm btn-primary w-100">
                            {{'share' | translate}} <i class="ml-1 fas fa-external-link-alt"></i>
                        </button>
                    </div>

                    <div class="col-6 mb-1">
                        <button (click)="viewHistory(item, true)"
                                class="btn btn-sm btn-primary w-100">
                            {{'view.history' | translate}} <i class="ml-1 fas fa-history"></i>
                        </button>
                    </div>

                    <div class="col-6 mb-1">
                        <button [routerLink]="'/camera-setting/' + item.cameraId" class="btn btn-sm btn-success w-100">
                            {{'config' | translate}}<i class="ml-1 fas fa-cogs"></i>
                        </button>
                    </div>

                    <div class="col-6 mb-1">
                        <div class="dropdown d-inline" ngbDropdown>
                            <button [id]="'ir' + i" class="btn btn-sm btn-success dropdown-toggle waves-effect w-100"
                                    ngbDropdownToggle
                                    type="button"> IR: {{item.ir_status | translate}}
                                <i class="mdi mdi-chevron-down float-right"></i></button>
                            <div aria-labelledby="ir{i}" class="dropdown-menu" ngbDropdownMenu>
                                <a (click)="setStatusIR('auto', i)" class="dropdown-item"
                                   href="javascript: void(0);">{{'auto' | translate}}</a>
                                <a (click)="setStatusIR('on', i)" class="dropdown-item"
                                   href="javascript: void(0);">{{'on' | translate}}</a>
                                <a (click)="setStatusIR('off', i)" class="dropdown-item"
                                   href="javascript: void(0);">{{'off' | translate}}</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <button (debounceClick)="refreshCamera(item, i)" *ngIf="isShakeHands[i]" appDebounceClick
                                class="btn btn-sm btn-warning w-100">
                            {{'refresh' | translate}}<i class="ml-1 fas fa-sync-alt"></i>
                        </button>
                    </div>

                    <div class="col-6">
                        <button (debounceClick)="disconectCamera(item, i)" *ngIf="isShakeHands[i]" appDebounceClick
                                class="btn btn-sm btn-danger w-100">
                            {{'turn_off' | translate}}<i class="ml-1 fas fa-power-off"></i>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<ng-template #qrCode let-modal="close">
    <div class="modal-header">
        <h4 class="modal-title">QR Code</h4>
        <button (click)="modal('Cross click')" aria-hidden="true" class="close" type="button">×</button>
    </div>
    <div class="modal-body text-center">
        <app-ui-preloader [display]="qrLoading"></app-ui-preloader>
        <form (ngSubmit)="onSubmitGenQR()" *ngIf="!qrShow" [formGroup]="genQRForm"
              class="needs-validation" name="loginForm" novalidate>

            <div class="form-group mb-3">
                <label for="wifiName">{{'wifi.name' | translate}}</label>

                <input [ngClass]="{ 'is-invalid': submitted && f.wifiName.errors }" class="form-control"
                       formControlName="wifiName"
                       id="wifiName" placeholder="{{'wifi.enter_name' | translate}}"
                       type="text"/>

                <div *ngIf="submitted && f.wifiName.errors" class="invalid-feedback">
                    <div *ngIf="f.wifiName.errors.required">{{'notify.validate.wifi_name' | translate}}</div>
                </div>
            </div>

            <div class="form-group mb-3">
                <label for="wifiPass">{{'password' | translate}}</label>

                <input [ngClass]="{ 'is-invalid': submitted && f.wifiPass.errors }" class="form-control"
                       formControlName="wifiPass"
                       id="wifiPass" placeholder="{{'enter_password' | translate}}"
                       type="text"/>

                <div *ngIf="submitted && f.wifiPass.errors" class="invalid-feedback">
                    <div *ngIf="f.wifiPass.errors.required">{{'notify.validate.password' | translate}}</div>
                    <!--                    <span *ngIf="f.wifiPass.errors.minlength">Mật khẩu phải có hơn 8 ký tự</span>-->
                </div>
            </div>

            <div class="form-group mb-0 text-center">
                <button class="btn btn-primary btn-block" type="submit">{{'get_the_code' | translate}} QR</button>
            </div>
        </form>

        <qrcode *ngIf="qrShow" [errorCorrectionLevel]="'M'" [qrdata]="qrData" [width]="256"></qrcode>

        <div class="row">
            <div class="col-6">
                <button (click)="backToAddCamera()" *ngIf="qrShow"
                        class="btn btn-primary w-100">{{'try_again' | translate}}</button>
            </div>

            <div class="col-6">
                <button (click)="refreshCameraList()" *ngIf="qrShow"
                        class="btn btn-success w-100">{{'notify.connected' | translate}}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #shareCamera let-modal="close">
    <div class="modal-header">
        <h4 class="modal-title">{{'camera.share' | translate}}</h4>
        <button (click)="modal('Cross click')" aria-hidden="true" class="close" type="button">×</button>
    </div>
    <div class="modal-body">
        <app-ui-preloader [display]="shareCamLoading"></app-ui-preloader>
        <form (ngSubmit)="onShareCamera()" [formGroup]="shareCamForm"
              class="needs-validation" name="loginForm" novalidate>

            <div class="form-group mb-3">
                <label>{{'username' | translate}}<span class="text-danger">*</span></label>

                <input #checkUsernameDebounce
                       [ngClass]="{ 'is-invalid': (shareCamSubmitted && scf.username.errors) || (usernameHasUse && scf.username.value)}"
                       class="form-control"
                       formControlName="username"
                       placeholder="{{'username' | translate}}"
                />

                <div *ngIf="submitted && scf.username.errors" class="invalid-feedback">
                    <span *ngIf="scf.username.errors.required">{{'notify.validate.username_plz' | translate}}</span>
                    <span *ngIf="scf.username.errors.pattern">{{'notify.validate.username_syntax' | translate}}</span>
                </div>

                <div *ngIf="!scf.username.errors" class="invalid-feedback">
                    <span *ngIf="usernameHasUse">{{'notify.validate.username_not_exist' | translate}}</span>
                </div>
            </div>

            <div class="form-group mb-0 text-center">
                <button class="btn btn-primary btn-block" type="submit">{{'share' | translate}}</button>
            </div>
        </form>
    </div>
</ng-template>


