import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CommandURL} from '../constant/ManageURLCommand';
import {BehaviorSubject} from 'rxjs';
import { Camera } from '../model/main';

@Injectable({
    providedIn: 'root'
})
export class PagesService {

    private apiData = new BehaviorSubject<any>(null);
    public apiData$ = this.apiData.asObservable();

    constructor(private http: HttpClient) {
        // console.log(this.http)
    }

    setData(data: Camera[]) {
        this.apiData.next(data);
    }

    fetchCameraList() {
        return this.http.post<any>(CommandURL.GET_LIST_CAMERA_URL, {});
    }

    fetchCameraListWithSetting() {
        return this.http.post<any>(CommandURL.GET_LIST_CAMERA_WITH_SETTING_URL, {});
    }

    fetchSettingCamera(cameraId: string) {
        return this.http.post<any>(CommandURL.GET_SETTING_CAMERA_CALIB_MOTION_ZONE_URL, {cameraId});
    }

    requestStreamSignal(cameraId: any, webToken: any) {
        return this.http.post<any>(CommandURL.REQUEST_STREAM_SIGNAL_URL, {cameraId, webToken});
    }

    requestStreamSignalWithTurn(cameraId: any, webToken: any, turnServer: any) {
        return this.http.post<any>(CommandURL.REQUEST_STREAM_SIGNAL_WITH_TURN_URL, {cameraId, webToken, turnServer});
    }

    requestAudioSignalWithTurn(cameraId: any, webToken: any, turnServer: any) {
        return this.http.post<any>(CommandURL.REQUEST_AUDIO_SIGNAL_WITH_TURN_URL, {cameraId, webToken, turnServer});
    }

    requestStreamSignalWithTurnWatcher(cameraId: any, webToken: any, turnServer: any) {
        return this.http.post<any>(CommandURL.REQUEST_STREAM_SIGNAL_WITH_TURN_WHATCHER_URL, {cameraId, webToken, turnServer});
    }

    getTurnCredential() {
        return this.http.get<any>(CommandURL.GET_TURN_CREDENTIAL);
    }

    setAlaemStatus(cameraId: string, status: string) {
        return this.http.post<any>(CommandURL.SET_ALARM_STATUS_URL, {cameraId, status});
    }

    renameCamera(cameraId: string, name: string) {
        return this.http.post<any>(CommandURL.SET_NAME_CAMERA, {cameraId, name});
    }

    setIRStatus(cameraId: string, status: string) {
        return this.http.post<any>(CommandURL.SET_IR_STATUS_URL, {cameraId, status});
    }

    shareCamera(json: any) {
        return this.http.post<any>(CommandURL.SHARE_CAMERA, json);
    }

    addDeviceToken(deviceToken: string) {
        return this.http.post<any>(CommandURL.ADD_DEVICE_TOKEN, {deviceToken});
    }

    setCamPTZ(cameraId: string, direction: string) {
        return this.http.post<any>(CommandURL.SET_CAM_PTZ_URL, {cameraId, direction});
    }
}
