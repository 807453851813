<!-- Topbar Start -->
<div class="navbar-custom">
    <div class="container-fluid">
        <ul class="list-unstyled topnav-menu float-right mb-0">

            <li class="dropdown notification-list">
                <!-- Mobile menu toggle-->
                <a (click)="toggleMobileMenu($event)" [ngClass]="{'open': openMobileMenu}"
                   class="navbar-toggle nav-link">
                    <div class="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
                <!-- End mobile menu toggle-->
            </li>

            <!--            <li class="d-none d-sm-block">-->
            <!--                <form class="app-search">-->
            <!--                    <div class="app-search-box">-->
            <!--                        <div class="input-group">-->
            <!--                            <input class="form-control" placeholder="Search..." type="text">-->
            <!--                            <div class="input-group-append">-->
            <!--                                <button class="btn" type="submit">-->
            <!--                                    <i class="fe-search"></i>-->
            <!--                                </button>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </form>-->
            <!--            </li>-->

            <li class="dropdown" ngbDropdown>
                <a aria-expanded="false" aria-haspopup="false"
                   class="nav-link dropdown-toggle mr-0 waves-effect waves-light"
                   href="javascript: void(0);" id="langDropdown" ngbDropdownToggle role="button">
                    <img alt="{{ selectedLanguage.name }}" class="mr-1" height="12" src="{{ selectedLanguage.flag }}">
                    <span
                            class="align-middle">{{ selectedLanguage.name }}</span> <i
                        class="ml-1 mdi mdi-chevron-down"></i>
                </a>
                <div aria-labelledby="langDropdown"
                     class="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu"
                     ngbDropdownMenu>
                    <!-- item-->
                    <a (click)="changeLanguage(language)" *ngFor="let language of languages;"
                       class="dropdown-item notify-item"
                       href="javascript:void(0);" ngbDropdownItem>
                        <img alt="{{ language.name }}" class="mr-1" height="12" src="{{ language.flag }}"> <span
                            class="align-middle">{{ language.name }}</span>
                    </a>
                </div>
            </li>

            <li [hidden]="true" class="dropdown notification-list" ngbDropdown>
                <a aria-expanded="false" aria-haspopup="false"
                   class="nav-link dropdown-toggle  waves-effect waves-light"
                   href="javascript: void(0);" id="notificationDropdown" ngbDropdownToggle role="button">
                    <i class="fe-bell noti-icon"></i>
                    <span class="badge badge-danger rounded-circle noti-icon-badge">4</span>
                </a>
                <div aria-labelledby="notificationDropdown"
                     class="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-lg" ngbDropdownMenu>

                    <!-- item-->
                    <div class="dropdown-item noti-title" ngbDropdownItem>
                        <h5 class="m-0">
              <span class="float-right">
                <a class="text-dark" href="javascript: void(0);">
                  <small>Clear All</small>
                </a>
              </span>Notification
                        </h5>
                    </div>

                    <div appSlimScroll class="slimscroll noti-scroll" id="notification-items">
                        <!-- item-->
                        <a *ngFor="let notification of notificationItems;" class="dropdown-item notify-item"
                           href="{{ notification.redirectTo }}" ngbDropdownItem>
                            <div class="notify-icon bg-soft-{{ notification.bgColor }} text-{{ notification.bgColor }}">
                                <i class="{{ notification.icon }}"></i>
                            </div>
                            <p class="notify-details">{{ notification.text }}
                                <small class="text-muted">{{ notification.subText }}</small>
                            </p>
                        </a>
                    </div>

                    <!-- All-->
                    <a class="dropdown-item text-center text-primary notify-item notify-all" href="javascript:void(0);">
                        View All
                    </a>
                </div>
            </li>


            <li class="dropdown notification-list" ngbDropdown (openChange)="dropdownAccount($event)">
                <a aria-expanded="false" aria-haspopup="false"
                   class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light" href="javascript: void(0);"
                   id="profileDropdown" ngbDropdownToggle
                   role="button">
                    <!--                    <img alt="user-image" class="rounded-circle" src="assets/images/users/avatar-1.jpg">-->
                    <i class="fas fa-user-circle fa-2x" style="vertical-align: middle"></i>
                    <span class="pro-user-name ml-1">{{username}}<i class="mdi mdi-chevron-down"></i></span>
                </a>

                <div *ngIf="dropdownOpen" aria-labelledby="profileDropdown" class="dropdown-menu dropdown-menu-right profile-dropdown"
                     ngbDropdownMenu>
                    <!-- <div class="dropdown-header noti-title align-items-center">
                        <h6 class="text-overflow m-0">
                            <i class="fas fa-wallet"></i>MetaMask
                        </h6>
                    </div>

                    <app-meta-mask [balances]="balances"></app-meta-mask>

                    <div class="dropdown-divider"></div>
                    <div class="dropdown-header noti-title align-items-center">
                        <h6 class="text-overflow m-0">
                            <i class="fas fa-wallet"></i>Phantom
                        </h6>
                    </div>

                    <app-phantom></app-phantom>-->

                    <!-- <div class="dropdown-divider"></div>  -->

                    <a class="dropdown-item notify-item" href="javascript:void(0);"
                       routerLink="/user-management" routerLinkActive="active">
                        <i class="remixicon-account-circle-line"></i>
                        <span>My Account</span>
                    </a>

                    <!-- item-->
                    <a (click)="logout()" class="dropdown-item notify-item" href="javascript:void(0);">
                        <i class="remixicon-logout-box-line"></i>
                        <span>Logout</span>
                    </a>

                </div>
            </li>


            <!--      <li class="dropdown notification-list">-->
            <!--        <a href="javascript:void(0);" class="nav-link right-bar-toggle waves-effect waves-light"-->
            <!--          (click)="toggleRightSidebar()">-->
            <!--          <i class="fe-settings noti-icon"></i>-->
            <!--        </a>-->
            <!--      </li>-->


        </ul>

        <!-- LOGO -->
        <div class="logo-box">
            <a [routerLink]="'/list-camera'" class="logo text-center">
        <span class="logo-lg">
          <img alt="" height="32" src="../../../assets/images/logo-icon/logo-aivis.png">
            <!--             <span class="logo-lg-text-light">Aivis</span>-->
        </span>
                <span class="logo-sm">
          <!-- <span class="logo-sm-text-dark">X</span> -->
          <img alt="" height="32" src="../../../assets/images/logo-icon/logo_aivis_white.png">
        </span>
            </a>
        </div>

        <!--        <ul class="list-unstyled topnav-menu topnav-menu-left m-0">-->
        <!--            <li class="dropdown d-none d-lg-block" ngbDropdown>-->
        <!--                <a aria-expanded="false" aria-haspopup="false"-->
        <!--                   class="nav-link dropdown-toggle waves-effect waves-light"-->
        <!--                   href="javascript: void(0);" id="extraMenu" ngbDropdownToggle role="button">-->
        <!--                    Create New-->
        <!--                    <i class="mdi mdi-chevron-down"></i>-->
        <!--                </a>-->
        <!--                <div aria-labelledby="extraMenu" class="dropdown-menu" ngbDropdownMenu>-->
        <!--                    &lt;!&ndash; item&ndash;&gt;-->
        <!--                    <a class="dropdown-item" href="javascript:void(0);">-->
        <!--                        <i class="fe-briefcase mr-1"></i>-->
        <!--                        <span>New Projects</span>-->
        <!--                    </a>-->

        <!--                    &lt;!&ndash; item&ndash;&gt;-->
        <!--                    <a class="dropdown-item" href="javascript:void(0);">-->
        <!--                        <i class="fe-user mr-1"></i>-->
        <!--                        <span>Create Users</span>-->
        <!--                    </a>-->

        <!--                    &lt;!&ndash; item&ndash;&gt;-->
        <!--                    <a class="dropdown-item" href="javascript:void(0);">-->
        <!--                        <i class="fe-bar-chart-line- mr-1"></i>-->
        <!--                        <span>Revenue Report</span>-->
        <!--                    </a>-->

        <!--                    &lt;!&ndash; item&ndash;&gt;-->
        <!--                    <a class="dropdown-item" href="javascript:void(0);">-->
        <!--                        <i class="fe-settings mr-1"></i>-->
        <!--                        <span>Settings</span>-->
        <!--                    </a>-->

        <!--                    <div class="dropdown-divider"></div>-->

        <!--                    &lt;!&ndash; item&ndash;&gt;-->
        <!--                    <a class="dropdown-item" href="javascript:void(0);">-->
        <!--                        <i class="fe-headphones mr-1"></i>-->
        <!--                        <span>Help & Support</span>-->
        <!--                    </a>-->

        <!--                </div>-->
        <!--            </li>-->
        <!--        </ul>-->
    </div>
</div>
<!-- end Topbar -->
