import {Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {SettingsService} from '../settings.service';
import {PagesService} from '../../../shared/service/pages.service';
import {Camera} from '../../../shared/model/main';

@Component({
    selector: 'app-group-setting',
    templateUrl: './group-setting.component.html',
    styleUrls: ['./group-setting.component.scss']
})
export class GroupSettingComponent implements OnInit {
    breadCrumbItems: Array<any>;
    loading = false;
    groupName = '';
    groupList: any = [];
    arrChecked: any = [];
    listCamIds: any = ['1', '2', '3'];
    groupSelected: any;

    listCamera: Array<Camera> = new Array<Camera>();

    keyLang: any;
    arrKeyLang = [
        'notify.update_data_success', 'success'
    ];

    constructor(
        private translate: TranslateService,
        private settingService: SettingsService,
        private pageService: PagesService,
    ) {
    }


    success = () => {
        Swal.fire({
            title: 'Success!',
            text: '',
            type: 'success',
            showConfirmButton: false,
            // confirmButtonClass: 'btn btn-confirm mt-2'
        });
    };

    ngOnInit() {
        this.breadCrumbItems = [{label: 'Aivis', path: '/list-camera'},
            {label: 'Camera setting group', active: true}];

        // this.translate.stream(this.arrKeyLang).subscribe(data => {
        //     this.keyLang = data;
        // });

        this.getList();

        this.pageService.apiData$.subscribe(res => {
            if (res && res.length > 0) {
                this.listCamera = res;
            }
        });
        // this.getListCamera();
    }

    getListCamera() {
        this.pageService.fetchCameraListWithSetting().subscribe((data) => {
            const res = data.cams || [];
            if (res && res.length > 0) {
                this.listCamera = res;
            }
        });
    }

    getList() {
        this.settingService.getListGroup().subscribe(res => {
            if (res.groups && res.groups.length > 0) {
                this.groupList = res.groups;
            }
        });
    }

    onSave() {
        if (this.groupName === '' || this.groupName === null) {
            return;
        }

        this.settingService.createGroup(this.groupName).subscribe(data => {
            if (data) {
                this.getList();
                this.success();
            }
        });
    }

    onDelete(item: any) {
        this.settingService.deleteGroup(item.id).subscribe(data => {
            if (data) {
                this.getList();
                this.success();
            }
        });
    }


    configGroup(e: any) {
        console.log(e);
    }

    checkAll(e: any, index: number) {
        // this.arrChecked.forEach((x, i) => {
        //     this.checked(e, i, index);
        // });
    }

    checked(e: any, item: any) {
        const checked = e.target.checked;
        if (checked) {
            this.settingService.addCamToGroup(item.cameraId, this.groupSelected).subscribe(data => {
                if (data) {
                    this.success();
                }
            });
        } else {
            this.settingService.removeCamOutOfGroup(item.cameraId, this.groupSelected).subscribe(data => {
                if (data) {
                    this.success();
                }
            });
        }

    }
}
