<!-- Navigation Bar-->
<header id="topnav">
    <app-topbar [balances]="balances"
                (mobileMenuButtonClicked)="onToggleMobileMenu()"
                (settingsButtonClicked)="onSettingsButtonClicked()"
                (changeLanguageClicked)="useLanguage($event)"
    >
    </app-topbar>

    <app-navbar [showMobileMenu]="showMobileMenu"></app-navbar>
</header>
<!-- End Navigation Bar-->

<div class="wrapper">
    <!-- content -->
    <router-outlet (activate)="componentAdded($event)" (deactivate)="componentRemoved($event)"></router-outlet>

    <div *ngIf="enable" [hidden]="!isShow" class="container-fluid">
        <app-list-camera [refershListCamera]="refershListCamera"></app-list-camera>
    </div>
</div>

<!-- footer -->
<app-footer></app-footer>
<!-- right sidebar -->
<!--<app-rightsidebar></app-rightsidebar>-->
