<!-- Right Sidebar -->
<div (clickOutside)="hide()" [exclude]="'.right-bar-toggle'" class="right-bar {{mode === 'REMOTE' ? ' w-custom' : ''}}">
    <div class="rightbar-title">
        <a (click)="hide();" class="right-bar-toggle float-right" href="javascript:void(0);">
            <i class="fe-x noti-icon"></i>
        </a>
        <h4 class="m-0 text-white">{{(mode === 'VIS_TIMELINE' ? 'move' : 'config') | translate}}</h4>
    </div>
    <ng-container [ngSwitch]="mode">
        <ng-container *ngSwitchCase="'VIS_TIMELINE'">
            <app-mobile-timeline [listTimeData]="listTimeData" [ngDateChoose]="ngDateChoose" (clickGetRecordVideo)="getRecordVideo($event)"></app-mobile-timeline>
        </ng-container>

        <ng-container *ngSwitchCase="'REMOTE'">
            ROMETE
        </ng-container>
    </ng-container>
</div>
<!-- /Right-bar -->

<!-- Right bar overlay-->
<div class="rightbar-overlay"></div>
