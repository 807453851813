<div appSlimScroll class="slimscroll-menu">
    <div class="row">
        <div class="col-12">
            <div class="timeline" dir="ltr">

                <article *ngFor="let data of timelineData; let i = index;"
                         [ngClass]="{ 'timeline-item-left': data.align === 'left'}"
                         class="timeline-item">
                    <div *ngIf="data.button">
                        <h2 class="m-0 d-none">&nbsp;</h2>
                        <div *ngIf="data.button" class="time-show">
                            <a class="btn btn-primary width-lg" href="javascript: void(0);">{{data.button}}</a>
                        </div>
                    </div>

                    <div class="timeline-desk">
                        <div *ngIf="!data.button" class="timeline-box">
                                <span [ngClass]="{'arrow-alt': data.align === 'left','arrow': data.align !== 'left'}"
                                      class="arrow"></span>

                            <span class="timeline-icon"><i class="mdi mdi-adjust"></i></span>
                            <button (click)="getRecordVideo(data)" class="btn btn-outline-primary">{{data.time}}</button>
                            <p class="text-muted"><small>{{data.minute}}</small></p>
                            <p class="mb-0">{{data.description}} </p>
                            <div *ngIf="data.image" class="timeline-album">
                                <a href="javascript: void(0);">
                                    <img alt="" src="{{data.image[0]}}">
                                </a>
                                <a href="javascript: void(0);">
                                    <img alt="" src="{{data.image[1]}}">
                                </a>
                                <a href="javascript: void(0);">
                                    <img alt="" src="{{data.image[2]}}">
                                </a>
                            </div>
                        </div>
                    </div>
                </article>

            </div>
            <!-- end timeline -->
        </div> <!-- end col -->
    </div>
</div> <!-- end slimscroll-menu-->
