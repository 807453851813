import {Component, Input, OnInit} from '@angular/core';
import {VisTimelineService} from "../vis-timeline.service";
import {ActivatedRoute} from "@angular/router";
import {Camera} from "../../../shared/model/main";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-history-search',
  templateUrl: './history-search.component.html',
  styleUrls: ['./history-search.component.scss']
})
export class HistorySearchComponent implements OnInit {
  @Input() earliestDate: any = null;
  @Input() actionType: any;

  constructor(

  ) {

  }

  ngOnInit(): void {
  }

  // onDateSelection(date: NgbDate) {
  //   this.actionType = 'all';
  //   const dateTimeChoose = new Date(date.year + '/' + date.month + '/' + date.day + ' 00:00:00').getTime();
  //   this.getOptions(dateTimeChoose);
  //
  //   if (this.timeline) {
  //     this.timeline.moveTo(dateTimeChoose);
  //     this.getTimelineData(dateTimeChoose, true);
  //   }
  //
  //   if (this.isMobileMode) {
  //     this.getTimelineData(dateTimeChoose, false);
  //   }
  //
  // }

}
