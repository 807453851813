<div class="container-fluid">
  <app-page-title title="Tickets" [breadcrumbItems]="breadCrumbItems"></app-page-title>


  <div class="row">
    <div class="col-12">
      <div class="card-box">

        <div class="text-center mb-2">
          <div class="row">
            <!-- Card -->
            <div class="col-md-6 col-xl-3" *ngFor="let carddata of cardData">
              <div class="card-box">
                <i class="{{ carddata.icon }} font-24"></i>
                <h3 class="text-warning" [ngClass]="{
                    'text-warning': carddata.text === 'warning',
                    'text-success': carddata.text === 'success',
                    'text-danger': carddata.text === 'danger'
                  }">{{carddata.tickets}}</h3>
                <p class="text-uppercase mb-1 font-13 font-weight-medium">{{carddata.title}}</p>
              </div>
            </div>
            <!-- End card -->
          </div>
        </div>
        <div class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div class="dataTables_length" id="tickets-table_length"><label
                class="d-inline-flex align-items-center">Show
                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                  [(ngModel)]="service.pageSize"
                  class="custom-select custom-select-sm form-control form-control-sm mx-2">
                  <option [ngValue]="10">10</option>
                  <option [ngValue]="25">25</option>
                  <option [ngValue]="50">50</option>
                  <option [ngValue]="100">100</option>
                </select> entries</label></div>
          </div>
          <div class="col-sm-12 col-md-6">
            <!-- Search -->
            <div id="tickets-table_filter" class="dataTables_filter text-md-right"><label
                class="d-inline-flex align-items-center">Search:
                <input type="text" name="searchTerm" class="form-control form-control-sm ml-2"
                  aria-controls="tickets-table" [(ngModel)]="service.searchTerm"></label></div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <!-- Table -->
            <div class="table-responsive">
              <table class="table datatables table-hover m-0 table-centered dt-responsive nowrap w-100" cellspacing="0"
                id="tickets-table">
                <thead class="bg-light">
                  <tr>
                    <th class="font-weight-medium" sortable="id" (sort)="onSort($event)">ID</th>
                    <th class="font-weight-medium" sortable="name" (sort)="onSort($event)">Requested By</th>
                    <th class="font-weight-medium" sortable="subject" (sort)="onSort($event)">Subject</th>
                    <th class="font-weight-medium">Assignee</th>
                    <th class="font-weight-medium" sortable="priority" (sort)="onSort($event)">Priority</th>
                    <th class="font-weight-medium" sortable="status" (sort)="onSort($event)">Status</th>
                    <th class="font-weight-medium" sortable="createddate" (sort)="onSort($event)">Created Date</th>
                    <th class="font-weight-medium" sortable="duedate" (sort)="onSort($event)">Due Date</th>
                    <th class="font-weight-medium">Action</th>
                  </tr>
                </thead>

                <tbody class="font-14">
                  <tr *ngFor="let ticket of tickets$ | async">
                    <td><b>{{ ticket.id }}</b></td>
                    <td>
                      <a href="javascript: void(0);" class="text-dark">
                        <img src="{{ ticket.requestuser }}" alt="contact-img" title="contact-img"
                          class="avatar-sm rounded-circle img-thumbnail" />
                        <span class="ml-2">
                          <ngb-highlight [result]="ticket.name" [term]="service.searchTerm"></ngb-highlight>
                        </span>
                      </a>
                    </td>

                    <td>
                      <ngb-highlight [result]="ticket.subject" [term]="service.searchTerm"></ngb-highlight>
                    </td>

                    <td>
                      <a href="javascript: void(0);">
                        <img src="{{ ticket.assignuser }}" alt="contact-img" title="contact-img"
                          class="avatar-sm rounded-circle img-thumbnail" />
                      </a>
                    </td>

                    <td>
                      <span class="badge badge-secondary" [ngClass]="{
                      'badge-warning': ticket.priority === 'Medium',
                      'badge-danger': ticket.priority === 'High',
                      'badge-secondary': ticket.priority === 'Low'
                    }">
                        <ngb-highlight [result]="ticket.priority" [term]="service.searchTerm"></ngb-highlight>
                      </span>
                    </td>

                    <td>
                      <span class="badge badge-success" [ngClass]="{
                      'badge-success': ticket.status === 'Open',
                      'badge-secondary': ticket.status === 'Closed'
                    }">
                        <ngb-highlight [result]="ticket.status" [term]="service.searchTerm"></ngb-highlight>
                      </span>
                    </td>

                    <td>
                      <ngb-highlight [result]="ticket.createddate" [term]="service.searchTerm"></ngb-highlight>
                    </td>

                    <td>
                      <ngb-highlight [result]="ticket.duedate" [term]="service.searchTerm"></ngb-highlight>
                    </td>

                    <td>
                      <ng-template [ngTemplateOutlet]="TableAction"></ng-template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- End Table -->
          </div>
        </div>
        <div class="row justify-content-md-between align-items-md-center mt-2">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">Showing 1 to
              {{service.pageSize}} of 15
              entries
            </div>
          </div>
          <div class="col-sm-12 col-md-5">
            <div class="text-md-right float-md-right">
              <!-- Pagination -->
              <!-- <ngb-pagination [collectionSize]="total$ | async" [(page)]="service.page" [pageSize]="service.pageSize">
              </ngb-pagination> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Table Action -->
<ng-template #TableAction>
  <div class="btn-group dropdown" ngbDropdown>
    <a href="javascript: void(0);" class="dropdown-toggle arrow-none btn btn-light btn-sm" ngbDropdownToggle
      data-toggle="dropdown" aria-expanded="false"><i class="mdi mdi-dots-horizontal"></i></a>
    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
      <a class="dropdown-item" href="javascript: void(0);"><i
          class="mdi mdi-pencil mr-2 text-muted font-18 vertical-middle"></i>Edit Ticket</a>
      <a class="dropdown-item" href="javascript: void(0);"><i
          class="mdi mdi-check-all mr-2 text-muted font-18 vertical-middle"></i>Close</a>
      <a class="dropdown-item" href="javascript: void(0);"><i
          class="mdi mdi-delete mr-2 text-muted font-18 vertical-middle"></i>Remove</a>
      <a class="dropdown-item" href="javascript: void(0);"><i
          class="mdi mdi-star mr-2 font-18 text-muted vertical-middle"></i>Mark as Unread</a>
    </div>
  </div>
</ng-template>