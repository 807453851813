<!--<button class="btn btn-primary" (click)="transfer()">transfer 10 ACH</button>-->
<ng-container *ngIf="!walletAddress">
    <a class="dropdown-item notify-item">
        <button class="btn btn-sm btn-primary" (click)="connectWallet()">connectWallet</button>
    </a>
</ng-container>

<ng-container *ngIf="walletAddress">
    <a class="dropdown-item notify-item" href="javascript:void(0);">
        <!--    <i class="remixicon-lock-line"></i>-->
        <!--    <p class="btn-text">-->
        <span class="font-weight-bold text-primary">ACH: </span> {{ balances[0] | number: "1.0-3" }}
        <!--    </p>-->
    </a>

    <a class="dropdown-item notify-item" href="javascript:void(0);">
        <!--    <i class="remixicon-lock-line"></i>-->
        <!--    <p class="btn-text">-->
        <span class="font-weight-bold text-primary">BNB: </span> {{ balances[1] | number: "1.0-3" }}
        <!--    </p>-->
    </a>
</ng-container>