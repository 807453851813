import {Component, Input, OnInit} from '@angular/core';
import {BlockchainService} from "../../../core/services/blockchain.service";

@Component({
    selector: 'app-meta-mask',
    templateUrl: './meta-mask.component.html',
    styleUrls: ['./meta-mask.component.scss']
})
export class MetaMaskComponent implements OnInit {
    @Input() balances: any;
    walletAddress: string;
    network: string;

    constructor(
        private blockchainService: BlockchainService,
    ) {
        this.balances = [];
        this.walletAddress = '';
        this.network = 'Unkown';
    }

    async ngOnInit(): Promise<void> {
        this.walletAddress = await this.blockchainService.getWalletAddress();
        console.log(this.walletAddress)
        this.getNetwork();
    }

    connectWallet() {
        this.blockchainService.loadWeb3();
    }

    async getNetwork() {
        this.network = await this.blockchainService.getNetwork();
        console.log(this.network);
    }

    transfer() {
        this.blockchainService.transfer('10');
    }
}
