import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIModule } from './ui/ui.module';
import { TimePipePipe } from './pipe/time-pipe.pipe';
import {DebounceClickDirective} from './directive/debounce-click.directive';
import { StatusIrPipePipe } from './pipe/status-ir-pipe.pipe';
import {MetaMaskComponent} from "./component/meta-mask/meta-mask.component";
import {PhantomComponent} from "./component/phantom/phantom.component";
import {FormsModule} from "@angular/forms";

@NgModule({
    declarations: [TimePipePipe, DebounceClickDirective, StatusIrPipePipe, MetaMaskComponent, PhantomComponent],
    imports: [
        CommonModule,
        UIModule,
        FormsModule
    ],
    exports: [
        TimePipePipe, DebounceClickDirective, StatusIrPipePipe, MetaMaskComponent, PhantomComponent
    ]
})
export class SharedModule { }
